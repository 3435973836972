export var IDL = {
    "version": "0.1.0",
    "name": "collection_distributor",
    "instructions": [
        {
            "name": "registerCollection",
            "accounts": [
                {
                    "name": "payer",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "numberingAuthority",
                    "isMut": false,
                    "isSigner": true
                },
                {
                    "name": "collectionMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "collectionMetadata",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "collectionNumbering",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "config",
                    "type": {
                        "defined": "RegisterCollectionConfig"
                    }
                }
            ]
        },
        {
            "name": "incrementCollection",
            "accounts": [
                {
                    "name": "payer",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "authority",
                    "isMut": false,
                    "isSigner": true
                },
                {
                    "name": "collectionNumbering",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "itemMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "itemMetadata",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "itemId",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": []
        },
        {
            "name": "createCollectionReserve",
            "accounts": [
                {
                    "name": "payer",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "reserveAuthority",
                    "isMut": false,
                    "isSigner": true
                },
                {
                    "name": "collectionNumbering",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "reserve",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "creator",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "name",
                    "type": "string"
                },
                {
                    "name": "curveParameter",
                    "type": "u16"
                }
            ]
        },
        {
            "name": "createTokenReserve",
            "accounts": [
                {
                    "name": "payer",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "reserve",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenReserve",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "mint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": []
        },
        {
            "name": "createItemClaimAccount",
            "accounts": [
                {
                    "name": "payer",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "reserve",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "itemMint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "itemId",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "itemClaimAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "mint",
                    "type": {
                        "option": "publicKey"
                    }
                }
            ]
        },
        {
            "name": "createUserDepositTracker",
            "accounts": [
                {
                    "name": "payer",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "depositor",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "reserve",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "depositTracker",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": []
        },
        {
            "name": "deposit",
            "accounts": [
                {
                    "name": "payer",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "depositor",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "depositTracker",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "depositorTokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "memo",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "reserve",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "mint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "reserveTokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "creatorTokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "creator",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "associatedTokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "message",
                    "type": "string"
                },
                {
                    "name": "amount",
                    "type": "u64"
                },
                {
                    "name": "split",
                    "type": "u16"
                }
            ]
        },
        {
            "name": "claim",
            "accounts": [
                {
                    "name": "payer",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "claimer",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "claimerTokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "collectionNumbering",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "reserve",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "tokenReserve",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "mint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "reserveTokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "nativeItemClaimAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "tokenItemClaimAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "itemId",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "itemTokenAccount",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "associatedTokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": []
        },
        {
            "name": "changeNumberingMaxSupply",
            "accounts": [
                {
                    "name": "authority",
                    "isMut": false,
                    "isSigner": true
                },
                {
                    "name": "collectionNumbering",
                    "isMut": true,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "newMaxSupply",
                    "type": {
                        "option": "u64"
                    }
                }
            ]
        }
    ],
    "accounts": [
        {
            "name": "itemClaimAccount",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "totalClaimed",
                        "type": "u64"
                    },
                    {
                        "name": "bump",
                        "type": "u8"
                    }
                ]
            }
        },
        {
            "name": "depositTracker",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "user",
                        "type": "publicKey"
                    },
                    {
                        "name": "reserve",
                        "type": "publicKey"
                    },
                    {
                        "name": "deposits",
                        "type": "u32"
                    },
                    {
                        "name": "bump",
                        "type": "u8"
                    }
                ]
            }
        },
        {
            "name": "memo",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "message",
                        "type": "string"
                    },
                    {
                        "name": "mint",
                        "type": "publicKey"
                    },
                    {
                        "name": "amount",
                        "type": "u64"
                    },
                    {
                        "name": "creatorShare",
                        "type": "u16"
                    }
                ]
            }
        },
        {
            "name": "collectionNumbering",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "name",
                        "type": "string"
                    },
                    {
                        "name": "authority",
                        "type": "publicKey"
                    },
                    {
                        "name": "collectionMint",
                        "type": "publicKey"
                    },
                    {
                        "name": "supply",
                        "type": "u64"
                    },
                    {
                        "name": "maxSupply",
                        "type": {
                            "option": "u64"
                        }
                    },
                    {
                        "name": "sharesMplCollectionUpdateAuth",
                        "type": "bool"
                    },
                    {
                        "name": "bump",
                        "type": "u8"
                    }
                ]
            }
        },
        {
            "name": "itemId",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "id",
                        "type": "u64"
                    },
                    {
                        "name": "bump",
                        "type": "u8"
                    }
                ]
            }
        },
        {
            "name": "reserve",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "name",
                        "type": "string"
                    },
                    {
                        "name": "authority",
                        "type": "publicKey"
                    },
                    {
                        "name": "collectionNumbering",
                        "type": "publicKey"
                    },
                    {
                        "name": "creator",
                        "type": "publicKey"
                    },
                    {
                        "name": "cumulativeNativeReserves",
                        "type": "u64"
                    },
                    {
                        "name": "prevNativeReserves",
                        "type": "u64"
                    },
                    {
                        "name": "curveParameter",
                        "type": "u16"
                    },
                    {
                        "name": "sharesNumberingAuthority",
                        "type": "bool"
                    },
                    {
                        "name": "bump",
                        "type": "u8"
                    }
                ]
            }
        },
        {
            "name": "tokenReserve",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "cumulativeTokenReserves",
                        "type": "u64"
                    },
                    {
                        "name": "prevTokenReserves",
                        "type": "u64"
                    },
                    {
                        "name": "bump",
                        "type": "u8"
                    }
                ]
            }
        }
    ],
    "types": [
        {
            "name": "RegisterCollectionConfig",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "name",
                        "type": "string"
                    },
                    {
                        "name": "maxSupply",
                        "type": {
                            "option": "u64"
                        }
                    }
                ]
            }
        },
        {
            "name": "Pda",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "address",
                        "type": "publicKey"
                    },
                    {
                        "name": "bump",
                        "type": "u8"
                    }
                ]
            }
        }
    ],
    "errors": [
        {
            "code": 6000,
            "name": "ClaimCalculationError",
            "msg": "Error in claim calculations"
        },
        {
            "code": 6001,
            "name": "InvalidNewMaxSupply",
            "msg": "new max supply must be greater than outstanding supply"
        }
    ]
};
