function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _iterableToArray(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import * as anchor from "@project-serum/anchor";
import { TOKEN_PROGRAM_ID, createInitializeMintInstruction, getMinimumBalanceForRentExemptMint, MINT_SIZE } from "@solana/spl-token";
import { SystemProgram, TransactionInstruction } from "@solana/web3.js";
import { COMPUTE_BUDGET_PROGRAM_ID } from "../constants";
export var createMintInstructions = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(payer, mint, mintAuthority, connection) {
        var lamports;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return getMinimumBalanceForRentExemptMint(connection);
                case 2:
                    lamports = _ctx.sent;
                    return _ctx.abrupt("return", [
                        SystemProgram.createAccount({
                            fromPubkey: payer,
                            newAccountPubkey: mint,
                            space: MINT_SIZE,
                            lamports: lamports,
                            programId: TOKEN_PROGRAM_ID
                        }),
                        createInitializeMintInstruction(mint, 0, mintAuthority, null, TOKEN_PROGRAM_ID), 
                    ]);
                case 4:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function createMintInstructions(payer, mint, mintAuthority, connection) {
        return _ref.apply(this, arguments);
    };
}();
export var additionalComputeBudgetIx = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(param) {
        var _multiplier, multiplier, _Uint8Array, data;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _multiplier = param.multiplier, multiplier = _multiplier === void 0 ? 2 : _multiplier;
                    ;
                    data = Buffer.from((_Uint8Array = Uint8Array).of.apply(_Uint8Array, [
                        0, 
                    ].concat(_toConsumableArray(new anchor.BN(200000 * multiplier).toArray("le", 4)), _toConsumableArray(new anchor.BN(0).toArray("le", 4)))));
                    return _ctx.abrupt("return", new TransactionInstruction({
                        keys: [],
                        programId: COMPUTE_BUDGET_PROGRAM_ID,
                        data: data
                    }));
                case 4:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function additionalComputeBudgetIx(_) {
        return _ref.apply(this, arguments);
    };
}();
