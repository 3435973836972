function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { PublicKey } from "@solana/web3.js";
export default function(context) {
    var getMetadata = function() {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(mint) {
            var nft;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        _ctx.next = 2;
                        return context.metaplex.nfts().findByMint(mint);
                    case 2:
                        nft = _ctx.sent;
                        return _ctx.abrupt("return", _objectSpread({
                            name: nft.name,
                            symbol: nft.symbol
                        }, nft.metadata));
                    case 4:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        }));
        return function getMetadata(mint) {
            return _ref.apply(this, arguments);
        };
    }();
    var findAllByWallet = function() {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(walletAddress) {
            var nfts;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        _ctx.next = 2;
                        return context.metaplex.nfts().findAllByOwner(walletAddress);
                    case 2:
                        nfts = _ctx.sent;
                        return _ctx.abrupt("return", nfts);
                    case 4:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        }));
        return function findAllByWallet(walletAddress) {
            return _ref.apply(this, arguments);
        };
    }();
    var isPartOfCollection = function() {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(nft, collection) {
            var ref, nftMetadata;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        ;
                        _ctx.next = 3;
                        return context.metaplex.nfts().findByMint(nft);
                    case 3:
                        nftMetadata = _ctx.sent;
                        return _ctx.abrupt("return", ((ref = nftMetadata.collection) === null || ref === void 0 ? void 0 : ref.key.toBase58()) === collection.toBase58() && nftMetadata.collection.verified);
                    case 5:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        }));
        return function isPartOfCollection(nft, collection) {
            return _ref.apply(this, arguments);
        };
    }();
    var getOwner = function() {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(mint) {
            var ref, ref1, largestAccounts, largestAccountInfo, owner;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        ;
                        _ctx.next = 3;
                        return context.provider.connection.getTokenLargestAccounts(mint);
                    case 3:
                        largestAccounts = _ctx.sent;
                        if (!(largestAccounts.value.length < 1)) {
                            _ctx.next = 6;
                            break;
                        }
                        throw new Error("No largest accounts for this mint");
                    case 6:
                        _ctx.next = 8;
                        return context.provider.connection.getParsedAccountInfo(largestAccounts.value[0].address);
                    case 8:
                        largestAccountInfo = _ctx.sent;
                        owner = (ref1 = (largestAccountInfo === null || largestAccountInfo === void 0 ? void 0 : (ref = largestAccountInfo.value) === null || ref === void 0 ? void 0 : ref.data).parsed.info) === null || ref1 === void 0 ? void 0 : ref1.owner;
                        if (owner) {
                            _ctx.next = 12;
                            break;
                        }
                        throw new Error("No owner exists");
                    case 12:
                        return _ctx.abrupt("return", new PublicKey(owner));
                    case 13:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        }));
        return function getOwner(mint) {
            return _ref.apply(this, arguments);
        };
    }();
    return {
        getMetadata: getMetadata,
        findAllByWallet: findAllByWallet,
        isPartOfCollection: isPartOfCollection,
        getOwner: getOwner
    };
};
