function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { COLLECTION_DISTRIBUTOR_PROGRAM_ID, DROP_TOKENS_DECIMAL_MODIFIER, MINT_REQUEST_PROGRAM_ID, NUMBERING_NAME, RESERVE_NAME, TOKEN_METADATA_PROGRAM_ID } from "../constants";
import { findCollectionPatrol, findMetadataAddress, findCollectionNumberingAddress, findCollectionReserveAddress, findAssociatedTokenAccountAddress, findMasterEditionAddress, findFollowerCollectionConfig, additionalComputeBudgetIx, findCollectionPatrolAddress, generateValidItem, createMintInstructions, findMintRequestAddress } from "../utils";
import { BN, web3 } from "@project-serum/anchor";
import { getAssociatedTokenAddress, createAssociatedTokenAccountInstruction, ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Keypair } from "@solana/web3.js";
export default function(context) {
    var create = function() {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(param) {
            var config, options, baseMint, collectionMint, collectionPatrol, collectionMetadataAddress, collectionNumbering, collectionReserveAddress, collectionTokenAccount, collectionMasterEdition, collectionUsdcAccount, creatorUsdcAccount, followerCollectionConfig, creatorUsdcTokenAccountExists, createCreatorUsdcTokenAccount, createCommunityReserveUsdcTokenAccount, createCollectionMetadataIx, mintPrice, maxSupply, decimalModifer, initializeCollectionIx, tx, signature;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        config = param.config, options = param.options, baseMint = param.baseMint;
                        _ctx.prev = 1;
                        collectionMint = Keypair.generate();
                        _ctx.next = 5;
                        return findCollectionPatrol(collectionMint.publicKey);
                    case 5:
                        collectionPatrol = _ctx.sent;
                        _ctx.next = 8;
                        return findMetadataAddress(collectionMint.publicKey);
                    case 8:
                        collectionMetadataAddress = _ctx.sent;
                        _ctx.next = 11;
                        return findCollectionNumberingAddress(collectionMint.publicKey, NUMBERING_NAME);
                    case 11:
                        collectionNumbering = _ctx.sent;
                        _ctx.next = 14;
                        return findCollectionReserveAddress(collectionNumbering, RESERVE_NAME);
                    case 14:
                        collectionReserveAddress = _ctx.sent;
                        _ctx.next = 17;
                        return findAssociatedTokenAccountAddress(collectionPatrol.address, collectionMint.publicKey);
                    case 17:
                        collectionTokenAccount = _ctx.sent;
                        _ctx.next = 20;
                        return findMasterEditionAddress(collectionMint.publicKey);
                    case 20:
                        collectionMasterEdition = _ctx.sent;
                        _ctx.next = 23;
                        return findAssociatedTokenAccountAddress(collectionReserveAddress, baseMint);
                    case 23:
                        collectionUsdcAccount = _ctx.sent;
                        _ctx.next = 26;
                        return getAssociatedTokenAddress(baseMint, context.provider.wallet.publicKey);
                    case 26:
                        creatorUsdcAccount = _ctx.sent;
                        _ctx.next = 29;
                        return findFollowerCollectionConfig(collectionMint.publicKey);
                    case 29:
                        followerCollectionConfig = _ctx.sent;
                        _ctx.next = 32;
                        return context.provider.connection.getAccountInfo(creatorUsdcAccount);
                    case 32:
                        creatorUsdcTokenAccountExists = _ctx.sent;
                        createCreatorUsdcTokenAccount = createAssociatedTokenAccountInstruction(context.provider.wallet.publicKey, creatorUsdcAccount, context.provider.wallet.publicKey, baseMint);
                        createCommunityReserveUsdcTokenAccount = createAssociatedTokenAccountInstruction(context.provider.wallet.publicKey, collectionUsdcAccount, collectionReserveAddress, baseMint);
                        _ctx.next = 37;
                        return context.followerCollectionProgram.methods.createCollectionMetadata(config).accounts({
                            payer: context.provider.wallet.publicKey,
                            creator: context.provider.wallet.publicKey,
                            collectionPatrol: collectionPatrol.address,
                            collectionMint: collectionMint.publicKey,
                            followerCollectionConfig: followerCollectionConfig.address,
                            collectionTokenAccount: collectionTokenAccount,
                            collectionMetadata: collectionMetadataAddress,
                            collectionMasterEdition: collectionMasterEdition,
                            rent: web3.SYSVAR_RENT_PUBKEY,
                            tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
                            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                            tokenProgram: TOKEN_PROGRAM_ID,
                            systemProgram: web3.SystemProgram.programId
                        }).instruction();
                    case 37:
                        createCollectionMetadataIx = _ctx.sent;
                        mintPrice = options.mintPrice, maxSupply = options.maxSupply;
                        ;
                        if (options.expectsNativePayment) {
                            decimalModifer = Math.pow(10, 9);
                        } else {
                            decimalModifer = Math.pow(10, 6);
                        }
                        _ctx.next = 43;
                        return context.followerCollectionProgram.methods.initializeCollection({
                            mintPrice: new BN(mintPrice * decimalModifer),
                            communityMintShare: options.communityMintShare,
                            secondaryRoyalty: options.royalty,
                            communitySecondaryRoyaltyShare: options.communityRoyaltyShare,
                            maxSupply: maxSupply ? new BN(maxSupply) : null,
                            expectsNativePayment: options.expectsNativePayment,
                            requiresMintRequest: options.requiresMintRequest,
                            reserveCurveParamater: 1000
                        }).accounts({
                            creator: context.provider.wallet.publicKey,
                            collectionPatrol: collectionPatrol.address,
                            collectionMint: collectionMint.publicKey,
                            followerCollectionConfig: followerCollectionConfig.address,
                            collectionMetadata: collectionMetadataAddress,
                            collectionNumbering: collectionNumbering,
                            creatorTokenAccount: creatorUsdcAccount,
                            communityTokenAccount: collectionUsdcAccount,
                            collectionReserve: collectionReserveAddress,
                            collectionDistributorProgram: COLLECTION_DISTRIBUTOR_PROGRAM_ID,
                            rent: web3.SYSVAR_RENT_PUBKEY,
                            systemProgram: web3.SystemProgram.programId
                        }).instruction();
                    case 43:
                        initializeCollectionIx = _ctx.sent;
                        tx = new web3.Transaction();
                        _ctx.t0 = tx;
                        _ctx.next = 48;
                        return additionalComputeBudgetIx({
                            multiplier: 2
                        });
                    case 48:
                        _ctx.t1 = _ctx.sent;
                        _ctx.t0.add.call(_ctx.t0, _ctx.t1);
                        if (!creatorUsdcTokenAccountExists) {
                            tx.add(createCreatorUsdcTokenAccount);
                        }
                        tx.add(createCommunityReserveUsdcTokenAccount);
                        tx.add(createCollectionMetadataIx);
                        tx.add(initializeCollectionIx);
                        _ctx.next = 56;
                        return context.provider.send(tx, [
                            collectionMint
                        ]);
                    case 56:
                        signature = _ctx.sent;
                        return _ctx.abrupt("return", {
                            signature: signature,
                            collectionMint: collectionMint.publicKey
                        });
                    case 60:
                        _ctx.prev = 60;
                        _ctx.t2 = _ctx["catch"](1);
                        throw _ctx.t2;
                    case 63:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    1,
                    60
                ]
            ]);
        }));
        return function create(_) {
            return _ref.apply(this, arguments);
        };
    }();
    var update = function() {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(param) {
            var collectionMint, newMaxSupply, newOptions, unlimitedMaxSupply, followerCollectionConfig, collectionNumbering, collectionPatrol, newMaxSupplyIx, newFollowerCollectionOptionsIx, tx, signature;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        collectionMint = param.collectionMint, newMaxSupply = param.newMaxSupply, newOptions = param.newOptions, unlimitedMaxSupply = param.unlimitedMaxSupply;
                        _ctx.next = 3;
                        return findFollowerCollectionConfig(collectionMint);
                    case 3:
                        followerCollectionConfig = _ctx.sent;
                        _ctx.next = 6;
                        return findCollectionNumberingAddress(collectionMint, NUMBERING_NAME);
                    case 6:
                        collectionNumbering = _ctx.sent;
                        _ctx.next = 9;
                        return findCollectionPatrolAddress(collectionMint);
                    case 9:
                        collectionPatrol = _ctx.sent;
                        newMaxSupplyIx = undefined;
                        if (!(newMaxSupply !== undefined || unlimitedMaxSupply && newMaxSupply === undefined)) {
                            _ctx.next = 15;
                            break;
                        }
                        _ctx.next = 14;
                        return context.followerCollectionProgram.methods.changeCollectionMaxSupply(unlimitedMaxSupply ? null : new BN(newMaxSupply !== null && newMaxSupply !== void 0 ? newMaxSupply : 0)).accounts({
                            creator: context.provider.wallet.publicKey,
                            followerCollectionConfig: followerCollectionConfig.address,
                            collectionMint: collectionMint,
                            collectionPatrol: collectionPatrol,
                            collectionNumbering: collectionNumbering,
                            collectionDistributorProgram: COLLECTION_DISTRIBUTOR_PROGRAM_ID
                        }).instruction();
                    case 14:
                        newMaxSupplyIx = _ctx.sent;
                    case 15:
                        newFollowerCollectionOptionsIx = undefined;
                        if (!(newOptions !== undefined)) {
                            _ctx.next = 20;
                            break;
                        }
                        _ctx.next = 19;
                        return context.followerCollectionProgram.methods.changeCollectionConfig({
                            newCreator: null,
                            newCommunityReserve: null,
                            newCreatorTokenAccount: null,
                            newCommunityTokenAccount: null,
                            newExpectsNativePayment: null,
                            newMintPrice: new BN(newOptions.mintPrice * DROP_TOKENS_DECIMAL_MODIFIER),
                            newCommunityMintShare: null,
                            newSecondaryRoyalty: null,
                            newCommunitySecondaryRoyaltyShare: null
                        }) // TODO: update this ts type to be BN, not number
                        .accounts({
                            creator: context.provider.wallet.publicKey,
                            followerCollectionConfig: followerCollectionConfig.address
                        }).instruction();
                    case 19:
                        newFollowerCollectionOptionsIx = _ctx.sent;
                    case 20:
                        tx = new web3.Transaction();
                        if (newMaxSupplyIx) {
                            tx.add(newMaxSupplyIx);
                        }
                        if (newFollowerCollectionOptionsIx) {
                            tx.add(newFollowerCollectionOptionsIx);
                        }
                        _ctx.next = 25;
                        return context.provider.send(tx);
                    case 25:
                        signature = _ctx.sent;
                        return _ctx.abrupt("return", {
                            signature: signature
                        });
                    case 27:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        }));
        return function update(_) {
            return _ref.apply(this, arguments);
        };
    }();
    var mint = function() {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(param) {
            var collectionMintAddress, collectionCreatorAddress, baseMint, collectionPatrol, collectionMetadataAddress, collectionMasterEditionAddress, collectionNumbering, ref, itemMint, metadataPda, masterEditionPda, itemIdPda, followerItemTokenAccount, itemMintAddress, itemMetadataAddress, itemMasterEditionAddress, followerUsdcAccount, followerCollectionConfig, creatorUsdcAccount, collectionReserveAddress, collectionUsdcAccount, createMintIxns, remainingAccounts, requiresMintRequest, mintRequest, createFollowerMetadataIx, initializeFollowIx, additionalComputeBudgetInstruction, tx, signatures;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        collectionMintAddress = param.collectionMintAddress, collectionCreatorAddress = param.collectionCreatorAddress, baseMint = param.baseMint;
                        _ctx.prev = 1;
                        _ctx.next = 4;
                        return findCollectionPatrol(collectionMintAddress);
                    case 4:
                        collectionPatrol = _ctx.sent;
                        _ctx.next = 7;
                        return findMetadataAddress(collectionMintAddress);
                    case 7:
                        collectionMetadataAddress = _ctx.sent;
                        _ctx.next = 10;
                        return findMasterEditionAddress(collectionMintAddress);
                    case 10:
                        collectionMasterEditionAddress = _ctx.sent;
                        _ctx.next = 13;
                        return findCollectionNumberingAddress(collectionMintAddress, NUMBERING_NAME);
                    case 13:
                        collectionNumbering = _ctx.sent;
                        _ctx.next = 16;
                        return generateValidItem(collectionMintAddress, context.provider.wallet.publicKey, collectionNumbering);
                    case 16:
                        ref = _ctx.sent;
                        itemMint = ref.itemMint;
                        metadataPda = ref.metadataPda;
                        masterEditionPda = ref.masterEditionPda;
                        itemIdPda = ref.itemIdPda;
                        followerItemTokenAccount = ref.followerItemTokenAccount;
                        itemMintAddress = itemMint.publicKey;
                        itemMetadataAddress = metadataPda.address;
                        itemMasterEditionAddress = masterEditionPda.address;
                        _ctx.next = 27;
                        return getAssociatedTokenAddress(baseMint, context.provider.wallet.publicKey);
                    case 27:
                        followerUsdcAccount = _ctx.sent;
                        _ctx.next = 30;
                        return findFollowerCollectionConfig(collectionMintAddress);
                    case 30:
                        followerCollectionConfig = _ctx.sent;
                        _ctx.next = 33;
                        return getAssociatedTokenAddress(baseMint, collectionCreatorAddress);
                    case 33:
                        creatorUsdcAccount = _ctx.sent;
                        _ctx.next = 36;
                        return findCollectionReserveAddress(collectionNumbering, RESERVE_NAME);
                    case 36:
                        collectionReserveAddress = _ctx.sent;
                        _ctx.next = 39;
                        return findAssociatedTokenAccountAddress(collectionReserveAddress, baseMint);
                    case 39:
                        collectionUsdcAccount = _ctx.sent;
                        _ctx.next = 42;
                        return createMintInstructions(context.provider.wallet.publicKey, itemMintAddress, collectionPatrol.address, context.provider.connection);
                    case 42:
                        createMintIxns = _ctx.sent;
                        remainingAccounts = [];
                        _ctx.next = 46;
                        return context.followerCollectionProgram.account.followerCollectionConfig.fetch(followerCollectionConfig.address).then(function(resp) {
                            return resp.requiresMintRequest;
                        });
                    case 46:
                        requiresMintRequest = _ctx.sent;
                        if (!requiresMintRequest) {
                            _ctx.next = 52;
                            break;
                        }
                        _ctx.next = 50;
                        return findMintRequestAddress(context.provider.wallet.publicKey, collectionCreatorAddress, collectionMintAddress, collectionPatrol.address);
                    case 50:
                        mintRequest = _ctx.sent;
                        remainingAccounts = [
                            {
                                isSigner: false,
                                isWritable: true,
                                pubkey: mintRequest
                            },
                            {
                                isSigner: false,
                                isWritable: false,
                                pubkey: MINT_REQUEST_PROGRAM_ID
                            }, 
                        ];
                    case 52:
                        _ctx.next = 54;
                        return context.followerCollectionProgram.methods.createFollowerMetadata().accounts({
                            payer: context.provider.wallet.publicKey,
                            payerTokenAccount: followerUsdcAccount,
                            follower: context.provider.wallet.publicKey,
                            itemMint: itemMintAddress,
                            followerItemTokenAccount: followerItemTokenAccount.address,
                            itemMetadata: itemMetadataAddress,
                            itemId: itemIdPda.address,
                            collectionMint: collectionMintAddress,
                            collectionNumbering: collectionNumbering,
                            followerCollectionConfig: followerCollectionConfig.address,
                            collectionPatrol: collectionPatrol.address,
                            creatorWallet: collectionCreatorAddress,
                            communityWallet: collectionReserveAddress,
                            creatorTokenAccount: creatorUsdcAccount,
                            communityTokenAccount: collectionUsdcAccount,
                            collectionMetadata: collectionMetadataAddress,
                            collectionMasterEdition: collectionMasterEditionAddress,
                            rent: web3.SYSVAR_RENT_PUBKEY,
                            collectionDistributorProgram: COLLECTION_DISTRIBUTOR_PROGRAM_ID,
                            tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
                            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                            tokenProgram: TOKEN_PROGRAM_ID,
                            systemProgram: web3.SystemProgram.programId
                        }).remainingAccounts(remainingAccounts).instruction();
                    case 54:
                        createFollowerMetadataIx = _ctx.sent;
                        _ctx.next = 57;
                        return context.followerCollectionProgram.methods.initializeFollow().accounts({
                            payer: context.provider.wallet.publicKey,
                            itemMint: itemMintAddress,
                            itemMetadata: itemMetadataAddress,
                            itemMasterEdition: itemMasterEditionAddress,
                            collectionPatrol: collectionPatrol.address,
                            collectionNumbering: collectionNumbering,
                            itemId: itemIdPda.address,
                            rent: web3.SYSVAR_RENT_PUBKEY,
                            tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
                            tokenProgram: TOKEN_PROGRAM_ID,
                            systemProgram: web3.SystemProgram.programId
                        }).instruction();
                    case 57:
                        initializeFollowIx = _ctx.sent;
                        _ctx.next = 60;
                        return additionalComputeBudgetIx({
                            multiplier: 1.7
                        });
                    case 60:
                        additionalComputeBudgetInstruction = _ctx.sent;
                        tx = new web3.Transaction().add(additionalComputeBudgetInstruction, createMintIxns[0], createMintIxns[1], createFollowerMetadataIx, initializeFollowIx);
                        ;
                        _ctx.prev = 63;
                        _ctx.next = 66;
                        return context.provider.send(tx, [
                            itemMint
                        ]);
                    case 66:
                        _ctx.t0 = _ctx.sent;
                        signatures = [
                            _ctx.t0
                        ];
                        _ctx.next = 75;
                        break;
                    case 70:
                        _ctx.prev = 70;
                        _ctx.t1 = _ctx["catch"](63);
                        _ctx.next = 74;
                        return context.provider.sendAll([
                            {
                                tx: new web3.Transaction().add(additionalComputeBudgetInstruction, createMintIxns[0], createMintIxns[1], createFollowerMetadataIx),
                                signers: [
                                    itemMint
                                ]
                            },
                            {
                                tx: new web3.Transaction().add(initializeFollowIx),
                                signers: []
                            }, 
                        ]);
                    case 74:
                        signatures = _ctx.sent;
                    case 75:
                        return _ctx.abrupt("return", {
                            signatures: signatures,
                            itemMint: itemMint.publicKey
                        });
                    case 78:
                        _ctx.prev = 78;
                        _ctx.t2 = _ctx["catch"](1);
                        throw _ctx.t2;
                    case 81:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    1,
                    78
                ],
                [
                    63,
                    70
                ]
            ]);
        }));
        return function mint(_) {
            return _ref.apply(this, arguments);
        };
    }();
    return {
        create: create,
        update: update,
        mint: mint
    };
};
