import { Program, Provider } from "@project-serum/anchor";
import { CATAPULT_PROGRAM_ID, COLLECTION_DISTRIBUTOR_PROGRAM_ID, FOLLOWER_COLLECTION_PROGRAM_ID, MINT_REQUEST_PROGRAM_ID } from "./constants";
import { CD_IDL, FC_IDL, MR_IDL, CATAPULT_IDL } from "./generated";
import { Metaplex } from "@metaplex-foundation/js-next";
var createContext = function(options) {
    var connection = options.connection, wallet = options.wallet, _opts = options.opts, opts = _opts === void 0 ? {} : _opts;
    var provider = new Provider(connection, wallet, opts);
    var followerCollectionProgram = new Program(FC_IDL, FOLLOWER_COLLECTION_PROGRAM_ID, provider);
    var collectionDistributionProgram = new Program(CD_IDL, COLLECTION_DISTRIBUTOR_PROGRAM_ID, provider);
    var mintRequestProgram = new Program(MR_IDL, MINT_REQUEST_PROGRAM_ID, provider);
    var metaplex = new Metaplex(connection);
    var catapultProgram = new Program(CATAPULT_IDL, CATAPULT_PROGRAM_ID, provider);
    return {
        provider: provider,
        options: options,
        followerCollectionProgram: followerCollectionProgram,
        collectionDistributionProgram: collectionDistributionProgram,
        mintRequestProgram: mintRequestProgram,
        catapultProgram: catapultProgram,
        metaplex: metaplex
    };
};
export default createContext;
