export var PROFILE_ROUTE = "/p";
export var ORG_ROUTE = "/org";
export var SOLSCAN_LINK = "https://solscan.io/token";
export var DISCORD_LINK = "https://discord.gg/moonbase";
export var URL_OR_EMPTY_STRING_REGEX = /^$|^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
export var FULL_URL_REGEX = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i;
//with or without http/www
export var PARTIAL_URL_REGEX = /^$|^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
// Without @ or any other symbol
export var EMPTY_OR_TWITTER_USERNAME_REGEX = /^$|^[\w]{1,15}$/i;
export var EMPTY_OR_INSTAGRAM_USERNAME_REGEX = /^$|^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/i;
export var EMPTY_OR_LINKEDIN_USERNAME_REGEX = /^$|^(?:[a-zA-Z\d]+(?:(?:|-|_)[a-zA-Z\d])*)+$/i;
export var MOONBASE_URL_REGEX = "https?://(dev.)?moonbase.com/(.*)|(@[w_]+)";
export var HANDLE_WITH_SYMBOL_REGEX = /\B@\w{3,}$/gi;
export var HANDLE_REGEX = /\w{3,}$/i;
export var HARMONIC_LABS_URL = "https://moonbase.com/hlabs";
export var AIRDROP_INFO_URL = "https://www.figma.com/proto/DqmxRBDnAtpS2VYbJvfkcv/airdrops?page-id=0%3A1&node-id=1%3A2&viewport=257%2C48%2C0.24&scaling=contain";
export var FOLLOW_ONE_PAGE_URL = "https://moonbase.com/one-pager.pdf";
// Extract anything beside number.
export var ANYTHING_BESIDE_NUMBER_REGEX = /[^-?\d]/g;
export var PRIVACY_POLICY_URL = "https://harmonicai.notion.site/Moonbase-Privacy-Policy-30380cd315234cf387794b5c4128e747";
export var TERMS_OF_SERVICE_URL = "https://harmonicai.notion.site/Moonbase-User-Agreement-de1263ea3dcd41cf9acc471df19b7828";
export var WHAT_IS_FOLLOWER_COLLECTION_URL = "https://harmonicai.notion.site/Moonbase-FAQ-ce4803c3b37147b1a88a9a1874ed9a22#9d9a2fc11d6e4c81affdc01b2d4196a9";
export var WHAT_IS_A_ROYALTY_URL = "https://harmonicai.notion.site/Moonbase-FAQ-ce4803c3b37147b1a88a9a1874ed9a22#e803f069d090424e888078910af68579";
export var WHAT_IS_NETWORK_FEE_URL = "https://harmonicai.notion.site/Moonbase-FAQ-ce4803c3b37147b1a88a9a1874ed9a22#62c25f9c4b35424093db807705f57b9e";
export var WHAT_CAN_I_DO_WITH_FOLLOW_URL = "https://harmonicai.notion.site/Moonbase-FAQ-ce4803c3b37147b1a88a9a1874ed9a22#e2a9b340e2414b4ab2e17379a8cabb96";
export var BUY_AND_SWAP_SOL_USDC_URL = "https://harmonicai.notion.site/Moonbase-FAQ-ce4803c3b37147b1a88a9a1874ed9a22#3ac9fdce185741a997a774c54263ebbb";
export var HOW_TO_PURCHASE_FOLLOW_URL = "https://harmonicai.notion.site/Moonbase-FAQ-ce4803c3b37147b1a88a9a1874ed9a22#931637165d514f4ca400ca6490317fc7";
export var MAX_SUPPLY_LEARN_MORE_URL = "https://harmonicai.notion.site/Moonbase-FAQ-ce4803c3b37147b1a88a9a1874ed9a22#95710272bad644c989520793a9426696";
export var FOLLOW_LEARN_MORE_URL = "https://harmonicai.notion.site/Moonbase-FAQ-ce4803c3b37147b1a88a9a1874ed9a22#4157d31f473942bd8f0318b187bdeb20";
export var USD_MULTIPLIER = 100;
// months start from zero.
export var selectMonthOptions = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December", 
].map(function(month, index) {
    return {
        label: month,
        value: index
    };
});
export var SHORT_COOKIE_AGE = 45 * 1000; // 45 seconds;
export var DEFAULT_LOGGED_IN_ROUTE = "/dashboard";
export var DATE_FORMAT = "MM/dd/yyyy";
export var SUPPORT_EMAIL = "help@moonbase.com";
//====================
// Form error messages
//====================
export var FORM_DATA_REQUIRED_ERROR_MESSAGE = "Required field";
export var FORM_START_DATE_IN_FUTURE_ERROR_MESSAGE = "Start date should not be in future";
export var FORM_ED_SMALLER_THAN_SD_ERROR_MESSAGE = "End date cannot be before start date";
export var FORM_YEAR_MONTH_REQUIRED_ERROR_MESSAGE = "Both year and month should be provided";
export var MAXIMUM_SEARCH_RESULTS = 10;
export var SOL_URN_PREFIX = "urn:moonbase:solana_address:";
export var DEFAULT_PROFILE_IMAGE = "/nft-image.png";
export var SPLIT_WORKSPACE_ID = "c9e30ab0-56b2-11ec-8d73-3a8f9f32462e";
