function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { NUMBERING_NAME, RESERVE_NAME } from "../constants";
import { findCollectionNumberingAddress, findCollectionReserveAddress, findItemIdAddress, findTokenItemClaimAccountAddress, findNativeItemClaimAccountAddress, findTokenReserveAddress, findAssociatedTokenAccountAddress, calculateClaimPercentage, calculateTotalAirdropForCollection, calculateTotalClaimable } from "../utils";
import { web3 } from "@project-serum/anchor";
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { SYSVAR_RENT_PUBKEY } from "@solana/web3.js";
export default function(context) {
    var claimWithItem = function() {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(param) {
            var baseMint, itemMint, collectionMint, collectionNumberingAddress, collectionReserveAddress, itemId, usdcItemClaimAccount, nativeItemClaimAccount, tokenReserveAddress, depositorUsdcAccount, collectionUsdcAccount, nftTokenAccount, shouldInitializeClaimAccounts, shouldInitializeTokenReserveAccount, createTokenItemClaimAccountIx, createNativeItemClaimAccountIx, createTokenReserveIx, claimIx, tx, signature;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        baseMint = param.baseMint, itemMint = param.itemMint, collectionMint = param.collectionMint;
                        _ctx.next = 3;
                        return findCollectionNumberingAddress(collectionMint, NUMBERING_NAME);
                    case 3:
                        collectionNumberingAddress = _ctx.sent;
                        _ctx.next = 6;
                        return findCollectionReserveAddress(collectionNumberingAddress, RESERVE_NAME);
                    case 6:
                        collectionReserveAddress = _ctx.sent;
                        _ctx.next = 9;
                        return findItemIdAddress(collectionNumberingAddress, itemMint);
                    case 9:
                        itemId = _ctx.sent;
                        _ctx.next = 12;
                        return findTokenItemClaimAccountAddress(collectionReserveAddress, itemId, baseMint);
                    case 12:
                        usdcItemClaimAccount = _ctx.sent;
                        _ctx.next = 15;
                        return findNativeItemClaimAccountAddress(collectionReserveAddress, itemId);
                    case 15:
                        nativeItemClaimAccount = _ctx.sent;
                        _ctx.next = 18;
                        return findTokenReserveAddress(collectionReserveAddress, baseMint);
                    case 18:
                        tokenReserveAddress = _ctx.sent;
                        _ctx.next = 21;
                        return findAssociatedTokenAccountAddress(context.provider.wallet.publicKey, baseMint);
                    case 21:
                        depositorUsdcAccount = _ctx.sent;
                        _ctx.next = 24;
                        return findAssociatedTokenAccountAddress(collectionReserveAddress, baseMint);
                    case 24:
                        collectionUsdcAccount = _ctx.sent;
                        _ctx.next = 27;
                        return findAssociatedTokenAccountAddress(context.provider.wallet.publicKey, itemMint);
                    case 27:
                        nftTokenAccount = _ctx.sent;
                        shouldInitializeClaimAccounts = false;
                        _ctx.prev = 29;
                        _ctx.next = 32;
                        return context.collectionDistributionProgram.account.itemClaimAccount.fetch(usdcItemClaimAccount);
                    case 32:
                        _ctx.next = 37;
                        break;
                    case 34:
                        _ctx.prev = 34;
                        _ctx.t0 = _ctx["catch"](29);
                        shouldInitializeClaimAccounts = true;
                    case 37:
                        shouldInitializeTokenReserveAccount = false;
                        _ctx.prev = 38;
                        _ctx.next = 41;
                        return context.collectionDistributionProgram.account.tokenReserve.fetch(tokenReserveAddress);
                    case 41:
                        _ctx.next = 46;
                        break;
                    case 43:
                        _ctx.prev = 43;
                        _ctx.t1 = _ctx["catch"](38);
                        shouldInitializeTokenReserveAccount = true;
                    case 46:
                        _ctx.next = 48;
                        return context.collectionDistributionProgram.methods.createItemClaimAccount(baseMint).accounts({
                            payer: context.provider.wallet.publicKey,
                            reserve: collectionReserveAddress,
                            itemMint: itemMint,
                            itemId: itemId,
                            itemClaimAccount: usdcItemClaimAccount,
                            systemProgram: web3.SystemProgram.programId
                        }).instruction();
                    case 48:
                        createTokenItemClaimAccountIx = _ctx.sent;
                        _ctx.next = 51;
                        return context.collectionDistributionProgram.methods.createItemClaimAccount(null).accounts({
                            payer: context.provider.wallet.publicKey,
                            reserve: collectionReserveAddress,
                            itemMint: itemMint,
                            itemId: itemId,
                            itemClaimAccount: nativeItemClaimAccount,
                            systemProgram: web3.SystemProgram.programId
                        }).instruction();
                    case 51:
                        createNativeItemClaimAccountIx = _ctx.sent;
                        _ctx.next = 54;
                        return context.collectionDistributionProgram.methods.createTokenReserve().accounts({
                            payer: context.provider.wallet.publicKey,
                            reserve: collectionReserveAddress,
                            tokenReserve: tokenReserveAddress,
                            mint: baseMint,
                            systemProgram: web3.SystemProgram.programId
                        }).instruction();
                    case 54:
                        createTokenReserveIx = _ctx.sent;
                        _ctx.next = 57;
                        return context.collectionDistributionProgram.methods.claim().accounts({
                            claimer: context.provider.wallet.publicKey,
                            claimerTokenAccount: depositorUsdcAccount,
                            collectionNumbering: collectionNumberingAddress,
                            reserve: collectionReserveAddress,
                            tokenReserve: tokenReserveAddress,
                            mint: baseMint,
                            reserveTokenAccount: collectionUsdcAccount,
                            nativeItemClaimAccount: nativeItemClaimAccount,
                            tokenItemClaimAccount: usdcItemClaimAccount,
                            itemId: itemId,
                            itemTokenAccount: nftTokenAccount,
                            rent: SYSVAR_RENT_PUBKEY,
                            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                            tokenProgram: TOKEN_PROGRAM_ID,
                            systemProgram: web3.SystemProgram.programId
                        }).instruction();
                    case 57:
                        claimIx = _ctx.sent;
                        tx = new web3.Transaction();
                        if (shouldInitializeClaimAccounts) {
                            tx.add(createTokenItemClaimAccountIx);
                            tx.add(createNativeItemClaimAccountIx);
                        }
                        if (shouldInitializeTokenReserveAccount) {
                            tx.add(createTokenReserveIx);
                        }
                        tx.add(claimIx);
                        _ctx.next = 64;
                        return context.provider.send(tx);
                    case 64:
                        signature = _ctx.sent;
                        return _ctx.abrupt("return", {
                            signature: signature
                        });
                    case 66:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    29,
                    34
                ],
                [
                    38,
                    43
                ]
            ]);
        }));
        return function claimWithItem(_) {
            return _ref.apply(this, arguments);
        };
    }();
    var findItemClaimData = function() {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(itemMint, collectionMint, baseMint) {
            var collectionNumberingAddress, collectionReserveAddress, collectionTokenAccount, tokenReserveAddress, itemIdAddress, itemClaimAccountAddress, itemId, collectionNumbering, tokenReserve, itemClaimAccount, collectionReserveBalance, _uiAmount, collectionReserveBalanceAmount, cumulativeTokenReserves, prevTokenReserves, totalClaimed, claimPercentage, totalAirdroppedToCollection, totalClaimableByItem;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        _ctx.next = 2;
                        return findCollectionNumberingAddress(collectionMint, NUMBERING_NAME);
                    case 2:
                        collectionNumberingAddress = _ctx.sent;
                        _ctx.next = 5;
                        return findCollectionReserveAddress(collectionNumberingAddress, RESERVE_NAME);
                    case 5:
                        collectionReserveAddress = _ctx.sent;
                        _ctx.next = 8;
                        return findAssociatedTokenAccountAddress(collectionReserveAddress, baseMint);
                    case 8:
                        collectionTokenAccount = _ctx.sent;
                        _ctx.next = 11;
                        return findTokenReserveAddress(collectionReserveAddress, baseMint);
                    case 11:
                        tokenReserveAddress = _ctx.sent;
                        _ctx.next = 14;
                        return findItemIdAddress(collectionNumberingAddress, itemMint);
                    case 14:
                        itemIdAddress = _ctx.sent;
                        _ctx.next = 17;
                        return findTokenItemClaimAccountAddress(collectionReserveAddress, itemIdAddress, baseMint);
                    case 17:
                        itemClaimAccountAddress = _ctx.sent;
                        _ctx.next = 20;
                        return context.collectionDistributionProgram.account.itemId.fetch(itemIdAddress);
                    case 20:
                        itemId = _ctx.sent;
                        _ctx.next = 23;
                        return context.collectionDistributionProgram.account.collectionNumbering.fetch(collectionNumberingAddress);
                    case 23:
                        collectionNumbering = _ctx.sent;
                        ;
                        _ctx.prev = 25;
                        _ctx.next = 28;
                        return context.collectionDistributionProgram.account.tokenReserve.fetch(tokenReserveAddress);
                    case 28:
                        tokenReserve = _ctx.sent;
                        _ctx.next = 31;
                        return context.collectionDistributionProgram.account.itemClaimAccount.fetch(itemClaimAccountAddress);
                    case 31:
                        itemClaimAccount = _ctx.sent;
                        _ctx.next = 36;
                        break;
                    case 34:
                        _ctx.prev = 34;
                        _ctx.t0 = _ctx["catch"](25);
                    case 36:
                        _ctx.next = 38;
                        return context.provider.connection.getTokenAccountBalance(collectionTokenAccount);
                    case 38:
                        collectionReserveBalance = _ctx.sent;
                        ;
                        collectionReserveBalanceAmount = ((_uiAmount = collectionReserveBalance.value.uiAmount) !== null && _uiAmount !== void 0 ? _uiAmount : 0) * Math.pow(10, collectionReserveBalance.value.decimals);
                        cumulativeTokenReserves = tokenReserve ? tokenReserve.cumulativeTokenReserves.toNumber() : 0;
                        prevTokenReserves = tokenReserve ? tokenReserve.prevTokenReserves.toNumber() : 0;
                        totalClaimed = itemClaimAccount ? itemClaimAccount.totalClaimed.toNumber() : 0;
                        claimPercentage = calculateClaimPercentage(itemId.id.toNumber(), collectionNumbering.supply.toNumber());
                        totalAirdroppedToCollection = 0;
                        totalAirdroppedToCollection = calculateTotalAirdropForCollection(collectionReserveBalanceAmount, prevTokenReserves, cumulativeTokenReserves);
                        totalClaimableByItem = calculateTotalClaimable(claimPercentage, totalAirdroppedToCollection, totalClaimed);
                        return _ctx.abrupt("return", {
                            totalClaimedByItem: totalClaimed,
                            totalClaimableByItem: totalClaimableByItem >= 0 ? totalClaimableByItem : 0,
                            totalAirdroppedToCollection: totalAirdroppedToCollection,
                            claimPercentage: claimPercentage
                        });
                    case 49:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    25,
                    34
                ]
            ]);
        }));
        return function findItemClaimData(itemMint, collectionMint, baseMint) {
            return _ref.apply(this, arguments);
        };
    }();
    return {
        claimWithItem: claimWithItem,
        findItemClaimData: findItemClaimData
    };
};
