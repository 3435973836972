function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { DEFAULT_BASE_TOKEN, MAINNET_USDC_MINT, WalletAdapterNetwork } from "@moonbase/crypto";
import { USDC_MULTIPLER } from "@moonbase/moonbase-sdk";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { toast } from "react-toastify";
import { TRANSACTION_PENDING_MESSAGE } from "./toasts";
export var getUsdcMint = function() {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
        return MAINNET_USDC_MINT;
    } else {
        return DEFAULT_BASE_TOKEN;
    }
};
export var formatPublicKey = function(publicKey) {
    return formatAddress(publicKey.toBase58());
};
export var formatAddress = function(address) {
    var first2 = address.substring(0, 2);
    var last4 = address.substring(address.length - 4, address.length);
    return "0x".concat(first2, "...").concat(last4);
};
export var getTokenNameFromPayload = function(payload) {
    if (payload.nftMetadata) {
        return payload.nftMetadata.metadataAccount.data.data.name;
    }
    if (payload.isNativeSol) {
        return "SOL";
    }
    return "USDC";
};
export var getQuicknodeEndpoint = function(cluster) {
    if (cluster === WalletAdapterNetwork.Mainnet) {
        return "https://winter-holy-violet.solana-mainnet.quiknode.pro/";
    }
    return "https://billowing-wild-sunset.solana-devnet.quiknode.pro/1ce91ef72704fefb63e1171a7f49474ee5a2dcc0/";
};
export var formatTokenAmountFromPayload = function(payload) {
    var ref;
    if (payload.nftMetadata && !((ref = payload.mint) === null || ref === void 0 ? void 0 : ref.equals(payload.nftMetadata.mint))) {
        return;
    }
    if (payload.isNativeSol) {
        return payload.amount / LAMPORTS_PER_SOL;
    }
    return payload.amount / USDC_MULTIPLER;
};
export var onLand = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(catapult, launch) {
        var signature;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    if (!catapult) {
                        _ctx.next = 12;
                        break;
                    }
                    toast.info(TRANSACTION_PENDING_MESSAGE);
                    _ctx.prev = 2;
                    _ctx.next = 5;
                    return catapult.land(launch);
                case 5:
                    signature = _ctx.sent.signature;
                    _ctx.next = 12;
                    break;
                case 8:
                    _ctx.prev = 8;
                    _ctx.t0 = _ctx["catch"](2);
                    console.error(_ctx.t0);
                    toast.error("There was an error landing this catapult launch.");
                case 12:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                2,
                8
            ]
        ]);
    }));
    return function onLand(catapult, launch) {
        return _ref.apply(this, arguments);
    };
}();
