export var calculateClaimPercentage = function(followerNumber, totalSupply) {
    var defaultCurveParameter = 1;
    if (followerNumber < 1) {
        throw new Error("Invalid follower number. Must be greater than 0.");
    }
    var areaUnderTheCurveForItem = Math.log((followerNumber + defaultCurveParameter) / followerNumber);
    var areaUnderTheWholeCurve = Math.log(totalSupply + defaultCurveParameter);
    var currentStake = areaUnderTheCurveForItem / areaUnderTheWholeCurve;
    return currentStake;
};
export var calculateTotalClaimable = function(claimPercentage, allTimeAirdropsToCollection, totalClaimedByItem) {
    return claimPercentage * allTimeAirdropsToCollection - totalClaimedByItem;
};
export var calculateTotalAirdropForCollection = function(collectionReserveBalance, prevTokenReserves, cumulativeTokenReserves) {
    // Token reserve gets updated on claims.
    // If no claims have occured, the total airdrop amount is equal to the collection reserve balance
    if (!cumulativeTokenReserves) {
        return collectionReserveBalance;
    }
    var totalAirdropAmount = 0;
    // Check if more has been airdropped after previous claim
    if (collectionReserveBalance > prevTokenReserves) {
        totalAirdropAmount = collectionReserveBalance + cumulativeTokenReserves - prevTokenReserves;
    } else {
        totalAirdropAmount = cumulativeTokenReserves;
    }
    return totalAirdropAmount;
};
