import { PublicKey } from "@solana/web3.js";
export var TOKEN_METADATA_PROGRAM_ID = new PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s");
export var FOLLOWER_COLLECTION_PROGRAM_ID = new PublicKey("342zaQ1jgejKvPMqcnZejuZ845NtnfkpGvo9j15gDmEL");
export var MINT_REQUEST_PROGRAM_ID = new PublicKey("ABU9Uy4Q5xjLXFnsqu3GjUFS8ZqDtFLUExUDyL5q1hfD");
export var COLLECTION_DISTRIBUTOR_PROGRAM_ID = new PublicKey("6rQQ3fRCYo9JNJQGAMX528GNRHVrqA1oEKZ6VKsxcWHi");
export var DEFAULT_BASE_TOKEN = new PublicKey("EXxU2Qn8ob1E3bxnP4wJMyS3hiVXMjfhhkS32dDVJZSH");
export var DROP_TOKENS_PROGRAM_ID = new PublicKey("Acnnt5yyKqeHMJpshbnXnM33z9bkfbkTJDw1STkE8fTJ");
export var COMPUTE_BUDGET_PROGRAM_ID = new PublicKey("ComputeBudget111111111111111111111111111111");
export var CATAPULT_PROGRAM_ID = new PublicKey("BVQS2ArK7x9au2XrfTiRnkk8DaKoe6LBtEHAfwKTtYos");
export var NUMBERING_NAME = "fc"; //hardocded in fc program
export var RESERVE_NAME = "fc_default"; //hardocded in fc program
export var DROP_TOKENS_DECIMAL_MODIFIER = Math.pow(10, 6);
export var USDC_MULTIPLER = Math.pow(10, 6);
export var METADATA_SEED = "metadata";
export var EDITION_SEED = "edition";
export var COLLECTION_PATROL_SEED = "collection_patrol";
export var COLLECTION_NUMBERING_SEED = "numbering";
export var COLLECTION_MINT_CONFIG_SEED = "mint_config";
export var ITEM_ID_SEED = "id";
export var DROP_TOKENS_PATROL_SEED = "patrol";
