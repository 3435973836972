function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import * as anchor from "@project-serum/anchor";
import * as web3 from "@solana/web3.js";
import { BN, Program } from "@project-serum/anchor";
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, getAssociatedTokenAddress } from "@solana/spl-token";
import { DROP_TOKENS_PROGRAM_ID, DROP_TOKENS_DECIMAL_MODIFIER } from "../constants";
import { DT_IDL } from "../generated";
import { findDropPatrolAddress } from "./locators";
export var createToken = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(provider, mintPair) {
        var program;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    program = new Program(DT_IDL, DROP_TOKENS_PROGRAM_ID, provider);
                    _ctx.t0 = program.methods.makeToken();
                    _ctx.t1 = provider.wallet.publicKey;
                    _ctx.t2 = mintPair.publicKey;
                    _ctx.next = 6;
                    return findDropPatrolAddress(mintPair.publicKey);
                case 6:
                    _ctx.t3 = _ctx.sent;
                    _ctx.t4 = web3.SYSVAR_RENT_PUBKEY;
                    _ctx.t5 = TOKEN_PROGRAM_ID;
                    _ctx.t6 = web3.SystemProgram.programId;
                    _ctx.t7 = {
                        creator: _ctx.t1,
                        mint: _ctx.t2,
                        patrol: _ctx.t3,
                        rent: _ctx.t4,
                        tokenProgram: _ctx.t5,
                        systemProgram: _ctx.t6
                    };
                    _ctx.next = 13;
                    return _ctx.t0.accounts.call(_ctx.t0, _ctx.t7).signers([
                        mintPair
                    ]).rpc();
                case 13:
                    return _ctx.abrupt("return", mintPair.publicKey);
                case 14:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function createToken(provider, mintPair) {
        return _ref.apply(this, arguments);
    };
}();
export var airdropDecimals = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(provider, mint, amount) {
        var amountBn, program, receiverTokenAccount;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    amountBn = new BN(amount * DROP_TOKENS_DECIMAL_MODIFIER);
                    program = new Program(DT_IDL, DROP_TOKENS_PROGRAM_ID, provider);
                    _ctx.next = 4;
                    return getAssociatedTokenAddress(mint, provider.wallet.publicKey);
                case 4:
                    receiverTokenAccount = _ctx.sent;
                    _ctx.t0 = program.methods.airdrop(amountBn);
                    _ctx.t1 = provider.wallet.publicKey;
                    _ctx.t2 = receiverTokenAccount;
                    _ctx.t3 = mint;
                    _ctx.next = 11;
                    return findDropPatrolAddress(mint);
                case 11:
                    _ctx.t4 = _ctx.sent;
                    _ctx.t5 = web3.SYSVAR_RENT_PUBKEY;
                    _ctx.t6 = ASSOCIATED_TOKEN_PROGRAM_ID;
                    _ctx.t7 = TOKEN_PROGRAM_ID;
                    _ctx.t8 = web3.SystemProgram.programId;
                    _ctx.t9 = {
                        receiver: _ctx.t1,
                        receiverTokenAccount: _ctx.t2,
                        mint: _ctx.t3,
                        patrol: _ctx.t4,
                        rent: _ctx.t5,
                        associatedTokenProgram: _ctx.t6,
                        tokenProgram: _ctx.t7,
                        systemProgram: _ctx.t8
                    };
                    _ctx.next = 19;
                    return _ctx.t0.accounts.call(_ctx.t0, _ctx.t9).rpc();
                case 19:
                    return _ctx.abrupt("return", receiverTokenAccount);
                case 20:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function airdropDecimals(provider, mint, amount) {
        return _ref.apply(this, arguments);
    };
}();
export var requestPublicDevnetAirdrop = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(to) {
        var wallet, provider;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    wallet = to;
                    provider = new anchor.Provider(getDropTokensPublicDevnetConnection(), wallet, getDropTokensConfirmOptions());
                    _ctx.t0 = provider.connection;
                    _ctx.next = 5;
                    return provider.connection.requestAirdrop(to, 1 * web3.LAMPORTS_PER_SOL);
                case 5:
                    _ctx.t1 = _ctx.sent;
                    _ctx.next = 8;
                    return _ctx.t0.confirmTransaction.call(_ctx.t0, _ctx.t1, "confirmed");
                case 8:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function requestPublicDevnetAirdrop(to) {
        return _ref.apply(this, arguments);
    };
}();
export var requestPublicTestnetAirdrop = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(to) {
        var wallet, provider;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    wallet = to;
                    provider = new anchor.Provider(new web3.Connection("https://api.testnet.solana.com", "processed"), wallet, getDropTokensConfirmOptions());
                    _ctx.t0 = provider.connection;
                    _ctx.next = 5;
                    return provider.connection.requestAirdrop(to, 1 * web3.LAMPORTS_PER_SOL);
                case 5:
                    _ctx.t1 = _ctx.sent;
                    _ctx.next = 8;
                    return _ctx.t0.confirmTransaction.call(_ctx.t0, _ctx.t1, "confirmed");
                case 8:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function requestPublicTestnetAirdrop(to) {
        return _ref.apply(this, arguments);
    };
}();
export var getDropTokensPublicDevnetConnection = function() {
    var endpoint = "https://api.devnet.solana.com";
    var commitment = "processed";
    return new web3.Connection(endpoint, commitment);
};
export var getDropTokensConfirmOptions = function() {
    var commitment = "processed";
    return {
        preflightCommitment: commitment
    };
};
