function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { PublicKey } from "@solana/web3.js";
import { Key, CollectionAuthorityRecord, Edition, EditionMarker, MasterEditionV1, MasterEditionV2, Metadata, ReservationListV1, ReservationListV2, UseAuthorityRecord, PROGRAM_ADDRESS } from "@metaplex-foundation/mpl-token-metadata";
export var mapMetaplexAccountInfoKeyToClass = function(key) {
    switch(key){
        case Key.CollectionAuthorityRecord:
            return CollectionAuthorityRecord;
        case Key.EditionV1:
            return Edition;
        case Key.EditionMarker:
            return EditionMarker;
        case Key.MasterEditionV1:
            return MasterEditionV1;
        case Key.MasterEditionV2:
            return MasterEditionV2;
        case Key.MetadataV1:
            return Metadata;
        case Key.ReservationListV1:
            return ReservationListV1;
        case Key.ReservationListV2:
            return ReservationListV2;
        case Key.UseAuthorityRecord:
            return UseAuthorityRecord;
    }
    return null;
};
export var mapMetaplexAccountInfoKeyToKeyAndClass = function(accountInfo) {
    var key = accountInfo.data.slice(0, 1).readUint8();
    var MetaplexAccountClass = mapMetaplexAccountInfoKeyToClass(key);
    return {
        key: key,
        MetaplexAccountClass: MetaplexAccountClass
    };
};
export var deserializeMetaplexAccountInfo = function(accountInfo) {
    var ref = mapMetaplexAccountInfoKeyToKeyAndClass(accountInfo), key = ref.key, MetaplexAccountClass = ref.MetaplexAccountClass;
    if (MetaplexAccountClass !== null) {
        return {
            key: key,
            MetaplexAccountClass: MetaplexAccountClass,
            result: MetaplexAccountClass.fromAccountInfo(accountInfo)
        };
    }
    return {
        key: key,
        MetaplexAccountClass: MetaplexAccountClass,
        result: null
    };
};
export var fetchAndParseMetaplexAccount = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(connection, accountId, commitment) {
        var accountKey, accountInfo, result;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    accountKey = typeof accountId === "string" ? new PublicKey(accountId) : accountId;
                    _ctx.next = 3;
                    return connection.getAccountInfo(accountKey, commitment);
                case 3:
                    accountInfo = _ctx.sent;
                    if (!(accountInfo !== null)) {
                        _ctx.next = 8;
                        break;
                    }
                    result = deserializeMetaplexAccountInfo(accountInfo).result;
                    if (!(result !== null)) {
                        _ctx.next = 8;
                        break;
                    }
                    return _ctx.abrupt("return", result[0]);
                case 8:
                    return _ctx.abrupt("return", null);
                case 9:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function fetchAndParseMetaplexAccount(connection, accountId, commitment) {
        return _ref.apply(this, arguments);
    };
}();
/*
  Based on https://metaplex.notion.site/Get-Collection-Methods-1ff0b118e4ce4605971df60e753a8559
  and https://github.com/metaplex-foundation/get-collection/blob/39116b680301c23c91e3960578c4a37c1c8e07c3/get-collection-ts/index.ts
*/ export var extractMetadataAddressesFromTransaction = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(tx) {
        var metadataAddresses, programIds, accountKeys, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, ix, metadataAddressIndex, metadata_address;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    metadataAddresses = [];
                    programIds = tx.transaction.message.programIds().map(function(p) {
                        return p.toString();
                    });
                    accountKeys = tx.transaction.message.accountKeys.map(function(p) {
                        return p.toString();
                    });
                    if (!programIds.includes(PROGRAM_ADDRESS)) {
                        _ctx.next = 21;
                        break;
                    }
                    _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                    _ctx.prev = 5;
                    // Go through all instructions in a given transaction
                    for(_iterator = tx.transaction.message.instructions[Symbol.iterator](); !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                        ix = _step.value;
                        // Filter for setAndVerify or verify instructions in the Metaplex token metadata program
                        if ((ix.data == "K" || ix.data == "S") && accountKeys[ix.programIdIndex] == PROGRAM_ADDRESS) {
                            metadataAddressIndex = ix.accounts[0];
                            metadata_address = tx.transaction.message.accountKeys[metadataAddressIndex];
                            metadataAddresses.push(metadata_address);
                        }
                    }
                    _ctx.next = 13;
                    break;
                case 9:
                    _ctx.prev = 9;
                    _ctx.t0 = _ctx["catch"](5);
                    _didIteratorError = true;
                    _iteratorError = _ctx.t0;
                case 13:
                    _ctx.prev = 13;
                    _ctx.prev = 14;
                    if (!_iteratorNormalCompletion && _iterator.return != null) {
                        _iterator.return();
                    }
                case 16:
                    _ctx.prev = 16;
                    if (!_didIteratorError) {
                        _ctx.next = 19;
                        break;
                    }
                    throw _iteratorError;
                case 19:
                    return _ctx.finish(16);
                case 20:
                    return _ctx.finish(13);
                case 21:
                    return _ctx.abrupt("return", metadataAddresses);
                case 22:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                5,
                9,
                13,
                21
            ],
            [
                14,
                ,
                16,
                20
            ]
        ]);
    }));
    return function extractMetadataAddressesFromTransaction(tx) {
        return _ref.apply(this, arguments);
    };
}();
// Source: https://github.com/metaplex-foundation/metaplex-program-library/blob/6140f637c8bde853de8efd1cbbf49ca14e9e349a/token-metadata/program/src/state.rs#L22-L60
export var MAX_NAME_LENGTH = 32;
export var MAX_SYMBOL_LENGTH = 10;
export var MAX_URI_LENGTH = 200;
export var MAX_CREATOR_LIMIT = 5;
export var MAX_CREATOR_LEN = 32 + 1 + 1;
export var MAX_DATA_SIZE = 4 + MAX_NAME_LENGTH + 4 + MAX_SYMBOL_LENGTH + 4 + MAX_URI_LENGTH + 2 + 1 + 4 + MAX_CREATOR_LIMIT * MAX_CREATOR_LEN;
export var MAX_METADATA_LEN = 1 + 32 + 32 + MAX_DATA_SIZE + 1 + 1 + 9 + 2 + 34 + 18 + 118; // Padding
