function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import * as web3 from "@solana/web3.js";
import { PublicKey } from "@solana/web3.js";
import { findAssociatedTokenAccountPda, findFollowerCollectionConfig, findItemIdPda, findMasterEditionPda, findMetadataPda } from ".";
import { TOKEN_METADATA_PROGRAM_ID } from "../constants";
export var tokenMintsForCollection = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(collectionMint, provider) {
        var config, responses, filteredResponses;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    config = {
                        filters: [
                            {
                                dataSize: 679
                            },
                            {
                                memcmp: {
                                    bytes: collectionMint.toBase58(),
                                    offset: 436
                                }
                            }, 
                        ]
                    };
                    _ctx.next = 3;
                    return provider.connection.getProgramAccounts(TOKEN_METADATA_PROGRAM_ID, config);
                case 3:
                    responses = _ctx.sent;
                    filteredResponses = responses.filter(function(response) {
                        var verified = response.account.data[435];
                        return verified === 1;
                    });
                    return _ctx.abrupt("return", filteredResponses.map(function(response) {
                        var mint = response.account.data.subarray(33, 65);
                        return new PublicKey(mint);
                    }));
                case 6:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function tokenMintsForCollection(collectionMint, provider) {
        return _ref.apply(this, arguments);
    };
}();
export var ownersForTokenMint = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(tokenMint, provider) {
        var config, responses;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    config = {
                        filters: [
                            {
                                dataSize: 165
                            },
                            {
                                memcmp: {
                                    bytes: tokenMint.toBase58(),
                                    offset: 0
                                }
                            }, 
                        ]
                    };
                    _ctx.next = 3;
                    return provider.connection.getProgramAccounts(TOKEN_PROGRAM_ID, config);
                case 3:
                    responses = _ctx.sent;
                    return _ctx.abrupt("return", responses.map(function(response) {
                        var tokenAccount = response.pubkey;
                        var owner = response.account.data.subarray(32, 64);
                        return {
                            tokenAccount: tokenAccount,
                            owner: new PublicKey(owner)
                        };
                    }));
                case 5:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function ownersForTokenMint(tokenMint, provider) {
        return _ref.apply(this, arguments);
    };
}();
//for each token mint, find the person who owns it
export var holdersForTokenMints = function() {
    var _ref1 = _asyncToGenerator(regeneratorRuntime.mark(function _callee1(tokenMints, provider) {
        return regeneratorRuntime.wrap(function _callee$(_ctx1) {
            while(1)switch(_ctx1.prev = _ctx1.next){
                case 0:
                    _ctx1.next = 2;
                    return Promise.all(tokenMints.map(function() {
                        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(tokenMint) {
                            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                while(1)switch(_ctx.prev = _ctx.next){
                                    case 0:
                                        _ctx.next = 2;
                                        return ownersForTokenMint(tokenMint, provider).then(function(owners) {
                                            return {
                                                tokenMint: tokenMint,
                                                tokenAccount: owners[0].tokenAccount,
                                                owner: owners[0].owner
                                            };
                                        });
                                    case 2:
                                        return _ctx.abrupt("return", _ctx.sent);
                                    case 3:
                                    case "end":
                                        return _ctx.stop();
                                }
                            }, _callee);
                        }));
                        return function(tokenMint) {
                            return _ref.apply(this, arguments);
                        };
                    }()));
                case 2:
                    return _ctx1.abrupt("return", _ctx1.sent);
                case 3:
                case "end":
                    return _ctx1.stop();
            }
        }, _callee1);
    }));
    return function holdersForTokenMints(tokenMints, provider) {
        return _ref1.apply(this, arguments);
    };
}();
export var holdersForCollection = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(collectionMint, provider) {
        var tokenMints;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return tokenMintsForCollection(collectionMint, provider);
                case 2:
                    tokenMints = _ctx.sent;
                    _ctx.next = 5;
                    return holdersForTokenMints(tokenMints, provider);
                case 5:
                    return _ctx.abrupt("return", _ctx.sent);
                case 6:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function holdersForCollection(collectionMint, provider) {
        return _ref.apply(this, arguments);
    };
}();
export var getFollowerCollectionMetadata = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(followerCollectionProgram, collectionMint) {
        var followerCollectionConfigPda, followerCollectionMetadata;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return findFollowerCollectionConfig(collectionMint);
                case 2:
                    followerCollectionConfigPda = _ctx.sent;
                    _ctx.next = 5;
                    return followerCollectionProgram.account.followerCollectionConfig.fetch(followerCollectionConfigPda.address);
                case 5:
                    followerCollectionMetadata = _ctx.sent;
                    return _ctx.abrupt("return", followerCollectionMetadata);
                case 7:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function getFollowerCollectionMetadata(followerCollectionProgram, collectionMint) {
        return _ref.apply(this, arguments);
    };
}();
// For every bump (back from 255), the program has to re-compute the hash to get the PDA address for every new account
export var generateValidItem = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(_collectionMintAddress, follower, collectionNumberingAddress) {
        var itemMint, itemMintAddress, metadataPda, masterEditionPda, itemId, followerItemTokenAccount;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    itemMint = web3.Keypair.generate();
                    itemMintAddress = itemMint.publicKey;
                    _ctx.next = 4;
                    return findMetadataPda(itemMintAddress);
                case 4:
                    metadataPda = _ctx.sent;
                    _ctx.next = 7;
                    return findMasterEditionPda(itemMintAddress);
                case 7:
                    masterEditionPda = _ctx.sent;
                    _ctx.next = 10;
                    return findItemIdPda(collectionNumberingAddress, itemMintAddress);
                case 10:
                    itemId = _ctx.sent;
                    _ctx.next = 13;
                    return findAssociatedTokenAccountPda(follower, itemMintAddress);
                case 13:
                    followerItemTokenAccount = _ctx.sent;
                case 14:
                    if (!(metadataPda.bump < 255 || masterEditionPda.bump < 253 || itemId.bump < 255 || followerItemTokenAccount.bump < 255)) {
                        _ctx.next = 31;
                        break;
                    }
                    itemMint = web3.Keypair.generate();
                    itemMintAddress = itemMint.publicKey;
                    _ctx.next = 19;
                    return findMetadataPda(itemMintAddress);
                case 19:
                    metadataPda = _ctx.sent;
                    _ctx.next = 22;
                    return findMasterEditionPda(itemMintAddress);
                case 22:
                    masterEditionPda = _ctx.sent;
                    _ctx.next = 25;
                    return findItemIdPda(collectionNumberingAddress, itemMintAddress);
                case 25:
                    itemId = _ctx.sent;
                    _ctx.next = 28;
                    return findAssociatedTokenAccountPda(follower, itemMintAddress);
                case 28:
                    followerItemTokenAccount = _ctx.sent;
                    _ctx.next = 14;
                    break;
                case 31:
                    return _ctx.abrupt("return", {
                        itemMint: itemMint,
                        metadataPda: metadataPda,
                        masterEditionPda: masterEditionPda,
                        itemIdPda: itemId,
                        followerItemTokenAccount: followerItemTokenAccount
                    });
                case 32:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function generateValidItem(_collectionMintAddress, follower, collectionNumberingAddress) {
        return _ref.apply(this, arguments);
    };
}();
