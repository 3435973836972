import { PublicKey } from "@solana/web3.js";
import { existsInTransactionLogs } from "@moonbase/solana-utils";
export var TransactionType;
(function(TransactionType) {
    TransactionType[TransactionType["Airdrop"] = 0] = "Airdrop";
})(TransactionType || (TransactionType = {}));
export var KnownPrograms = {
    tokenProgram: new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA")
};
export var getTransactionType = function(transaction) {
    if (existsInTransactionLogs(transaction, "Instruction: Deposit")) {
        return TransactionType.Airdrop;
    } else {
        return null;
    }
};
export var filterTransactionsByType = function(transactions, type) {
    return transactions.filter(function(t) {
        if (!t) {
            return false;
        }
        return getTransactionType(t) === type;
    });
};
