function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { findInnerInstructionsForProgramAddress } from "@moonbase/solana-utils";
import { KnownPrograms } from "./transaction";
export var parseAirdropTx = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(transaction, connection) {
        var ref, ref1, ref2, ref3, ref4, tokenTransferInstructions, tokenTransferInstruction, source, destination, accountKeys, sourcePubkey, destinationPubkey, parsed, payerPubkey, preTokenBalance, postTokenBalance, _uiAmount, _uiAmount1, amountAirdropped;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    ;
                    tokenTransferInstructions = findInnerInstructionsForProgramAddress(transaction, KnownPrograms.tokenProgram);
                    if (!(tokenTransferInstructions.length !== 1)) {
                        _ctx.next = 4;
                        break;
                    }
                    throw new Error("Inner instructions are invalid for deposit IX. Multiple token transfers occuring");
                case 4:
                    tokenTransferInstruction = tokenTransferInstructions[0];
                    source = tokenTransferInstruction.accounts[0];
                    destination = tokenTransferInstruction.accounts[1];
                    accountKeys = transaction.transaction.message.accountKeys;
                    sourcePubkey = accountKeys[source];
                    destinationPubkey = accountKeys[destination];
                    _ctx.next = 12;
                    return connection.getParsedAccountInfo(sourcePubkey);
                case 12:
                    parsed = _ctx.sent;
                    payerPubkey = ((ref = parsed.value) === null || ref === void 0 ? void 0 : ref.data).parsed.info.owner;
                    if (payerPubkey) {
                        _ctx.next = 16;
                        break;
                    }
                    throw new Error("Unable to parse payer public key.");
                case 16:
                    preTokenBalance = transaction === null || transaction === void 0 ? void 0 : (ref1 = transaction.meta) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.preTokenBalances) === null || ref2 === void 0 ? void 0 : ref2.find(function(o) {
                        return o.accountIndex === destination;
                    });
                    postTokenBalance = transaction === null || transaction === void 0 ? void 0 : (ref3 = transaction.meta) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.postTokenBalances) === null || ref4 === void 0 ? void 0 : ref4.find(function(o) {
                        return o.accountIndex === destination;
                    });
                    if (!(!(preTokenBalance === null || preTokenBalance === void 0 ? void 0 : preTokenBalance.uiTokenAmount) || !(postTokenBalance === null || postTokenBalance === void 0 ? void 0 : postTokenBalance.uiTokenAmount))) {
                        _ctx.next = 20;
                        break;
                    }
                    throw new Error("could not find the pre/post balances with the derived destination account index");
                case 20:
                    ;
                    amountAirdropped = ((_uiAmount = postTokenBalance.uiTokenAmount.uiAmount) !== null && _uiAmount !== void 0 ? _uiAmount : 0) - ((_uiAmount1 = preTokenBalance.uiTokenAmount.uiAmount) !== null && _uiAmount1 !== void 0 ? _uiAmount1 : 0);
                    return _ctx.abrupt("return", {
                        airdropAmount: amountAirdropped,
                        source: payerPubkey,
                        destination: destinationPubkey
                    });
                case 23:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function parseAirdropTx(transaction, connection) {
        return _ref.apply(this, arguments);
    };
}();
export var getMemoNonce = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(collectionDistributionProgram, depositTrackerAddress) {
        var depositTracker;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.prev = 0;
                    _ctx.next = 3;
                    return collectionDistributionProgram.account.depositTracker.fetch(depositTrackerAddress);
                case 3:
                    depositTracker = _ctx.sent;
                    return _ctx.abrupt("return", depositTracker.deposits);
                case 7:
                    _ctx.prev = 7;
                    _ctx.t0 = _ctx["catch"](0);
                    return _ctx.abrupt("return", 0);
                case 10:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                0,
                7
            ]
        ]);
    }));
    return function getMemoNonce(collectionDistributionProgram, depositTrackerAddress) {
        return _ref.apply(this, arguments);
    };
}();
