function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { SystemProgram, SYSVAR_RENT_PUBKEY, Transaction } from "@solana/web3.js";
import { getFollowerCollectionMetadata } from "../utils/helpers";
import { NUMBERING_NAME, RESERVE_NAME } from "../constants";
import { findCollectionNumberingAddress, findCollectionReserveAddress, findDepositTrackerAddress, findAssociatedTokenAccountAddress, findMemoAddress, getMemoNonce, findTokenReserveAddress, calculateTotalAirdropForCollection, filterTransactionsByType, parseAirdropTx, TransactionType } from "../utils";
import { ASSOCIATED_TOKEN_PROGRAM_ID, getAssociatedTokenAddress, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { BN } from "@project-serum/anchor";
import { getAllTransactionsForAccount } from "@moonbase/solana-utils";
export default function(context) {
    var airdrop = function() {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(param) {
            var collectionMint, message, airdropAmount, creatorSplit, baseMint, depositorTokenAccount, collectionNumberingAddress, collectionReserveAddress, depositTrackerAddress, collectionTokenAccount, followerCollectionConfigMetadata, creatorAddress, creatorTokenAccount, nonce, memoAddress, createUserDepositTrackerIx, depositIx, tx, signature;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        collectionMint = param.collectionMint, message = param.message, airdropAmount = param.airdropAmount, creatorSplit = param.creatorSplit, baseMint = param.baseMint;
                        _ctx.next = 3;
                        return getAssociatedTokenAddress(baseMint, context.provider.wallet.publicKey);
                    case 3:
                        depositorTokenAccount = _ctx.sent;
                        _ctx.next = 6;
                        return findCollectionNumberingAddress(collectionMint, NUMBERING_NAME);
                    case 6:
                        collectionNumberingAddress = _ctx.sent;
                        _ctx.next = 9;
                        return findCollectionReserveAddress(collectionNumberingAddress, RESERVE_NAME);
                    case 9:
                        collectionReserveAddress = _ctx.sent;
                        _ctx.next = 12;
                        return findDepositTrackerAddress(collectionReserveAddress, context.provider.wallet.publicKey);
                    case 12:
                        depositTrackerAddress = _ctx.sent;
                        _ctx.next = 15;
                        return findAssociatedTokenAccountAddress(collectionReserveAddress, baseMint);
                    case 15:
                        collectionTokenAccount = _ctx.sent;
                        _ctx.next = 18;
                        return getFollowerCollectionMetadata(context.followerCollectionProgram, collectionMint);
                    case 18:
                        followerCollectionConfigMetadata = _ctx.sent;
                        creatorAddress = followerCollectionConfigMetadata.creator;
                        creatorTokenAccount = followerCollectionConfigMetadata.creatorTokenAccount;
                        _ctx.next = 23;
                        return getMemoNonce(context.collectionDistributionProgram, depositTrackerAddress);
                    case 23:
                        nonce = _ctx.sent;
                        _ctx.next = 26;
                        return findMemoAddress(collectionReserveAddress, context.provider.wallet.publicKey, nonce);
                    case 26:
                        memoAddress = _ctx.sent;
                        _ctx.next = 29;
                        return context.collectionDistributionProgram.methods.createUserDepositTracker().accounts({
                            payer: context.provider.wallet.publicKey,
                            depositor: context.provider.wallet.publicKey,
                            reserve: collectionReserveAddress,
                            depositTracker: depositTrackerAddress,
                            systemProgram: SystemProgram.programId
                        }).instruction();
                    case 29:
                        createUserDepositTrackerIx = _ctx.sent;
                        _ctx.next = 32;
                        return context.collectionDistributionProgram.methods.deposit(message, new BN(airdropAmount), creatorSplit).accounts({
                            payer: context.provider.wallet.publicKey,
                            depositor: context.provider.wallet.publicKey,
                            depositTracker: depositTrackerAddress,
                            depositorTokenAccount: depositorTokenAccount,
                            memo: memoAddress,
                            reserve: collectionReserveAddress,
                            mint: baseMint,
                            reserveTokenAccount: collectionTokenAccount,
                            creatorTokenAccount: creatorTokenAccount,
                            creator: creatorAddress,
                            rent: SYSVAR_RENT_PUBKEY,
                            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                            tokenProgram: TOKEN_PROGRAM_ID,
                            systemProgram: SystemProgram.programId
                        }).instruction();
                    case 32:
                        depositIx = _ctx.sent;
                        tx = new Transaction();
                        if (nonce === 0) {
                            tx.add(createUserDepositTrackerIx);
                        }
                        tx.add(depositIx);
                        _ctx.next = 38;
                        return context.provider.send(tx);
                    case 38:
                        signature = _ctx.sent;
                        return _ctx.abrupt("return", {
                            signature: signature
                        });
                    case 40:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        }));
        return function airdrop(_) {
            return _ref.apply(this, arguments);
        };
    }();
    var findVolumeByMint = function() {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(collectionMint, baseMint) {
            var collectionNumberingAddress, collectionReserveAddress, collectionTokenAccount, collectionReserveBalance, _uiAmount, collectionReserveBalanceAmount, tokenReserveAddress, tokenReserve, cumulativeTokenReserves, prevTokenReserves, totalAirdropAmount;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        _ctx.next = 2;
                        return findCollectionNumberingAddress(collectionMint, NUMBERING_NAME);
                    case 2:
                        collectionNumberingAddress = _ctx.sent;
                        _ctx.next = 5;
                        return findCollectionReserveAddress(collectionNumberingAddress, RESERVE_NAME);
                    case 5:
                        collectionReserveAddress = _ctx.sent;
                        _ctx.next = 8;
                        return findAssociatedTokenAccountAddress(collectionReserveAddress, baseMint);
                    case 8:
                        collectionTokenAccount = _ctx.sent;
                        _ctx.next = 11;
                        return context.options.connection.getTokenAccountBalance(collectionTokenAccount);
                    case 11:
                        collectionReserveBalance = _ctx.sent;
                        ;
                        collectionReserveBalanceAmount = ((_uiAmount = collectionReserveBalance.value.uiAmount) !== null && _uiAmount !== void 0 ? _uiAmount : 0) * Math.pow(10, collectionReserveBalance.value.decimals);
                        _ctx.prev = 14;
                        _ctx.next = 17;
                        return findTokenReserveAddress(collectionReserveAddress, baseMint);
                    case 17:
                        tokenReserveAddress = _ctx.sent;
                        _ctx.next = 20;
                        return context.collectionDistributionProgram.account.tokenReserve.fetch(tokenReserveAddress);
                    case 20:
                        tokenReserve = _ctx.sent;
                        cumulativeTokenReserves = tokenReserve.cumulativeTokenReserves.toNumber();
                        prevTokenReserves = tokenReserve.prevTokenReserves.toNumber();
                        totalAirdropAmount = calculateTotalAirdropForCollection(collectionReserveBalanceAmount, prevTokenReserves, cumulativeTokenReserves);
                        return _ctx.abrupt("return", totalAirdropAmount / Math.pow(10, collectionReserveBalance.value.decimals));
                    case 27:
                        _ctx.prev = 27;
                        _ctx.t0 = _ctx["catch"](14);
                        return _ctx.abrupt("return", collectionReserveBalanceAmount / Math.pow(10, collectionReserveBalance.value.decimals));
                    case 30:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    14,
                    27
                ]
            ]);
        }));
        return function findVolumeByMint(collectionMint, baseMint) {
            return _ref.apply(this, arguments);
        };
    }();
    var findAllByMint = function() {
        var _ref1 = _asyncToGenerator(regeneratorRuntime.mark(function _callee1(param) {
            var collectionMint, baseMint, collectionNumberingAddress, collectionReserveAddress, collectionTokenAccount, transactionsForCollectionTokenAccouont, airdropTxs, parsedAirdrops, airdrops;
            return regeneratorRuntime.wrap(function _callee$(_ctx1) {
                while(1)switch(_ctx1.prev = _ctx1.next){
                    case 0:
                        collectionMint = param.collectionMint, baseMint = param.baseMint;
                        _ctx1.next = 3;
                        return findCollectionNumberingAddress(collectionMint, NUMBERING_NAME);
                    case 3:
                        collectionNumberingAddress = _ctx1.sent;
                        _ctx1.next = 6;
                        return findCollectionReserveAddress(collectionNumberingAddress, RESERVE_NAME);
                    case 6:
                        collectionReserveAddress = _ctx1.sent;
                        _ctx1.next = 9;
                        return findAssociatedTokenAccountAddress(collectionReserveAddress, baseMint);
                    case 9:
                        collectionTokenAccount = _ctx1.sent;
                        _ctx1.next = 12;
                        return getAllTransactionsForAccount(collectionTokenAccount, context.options.connection);
                    case 12:
                        transactionsForCollectionTokenAccouont = _ctx1.sent;
                        airdropTxs = filterTransactionsByType(transactionsForCollectionTokenAccouont, TransactionType.Airdrop);
                        parsedAirdrops = airdropTxs.map(function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(tx) {
                                var parsedAirdrop;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            if (tx) {
                                                _ctx.next = 2;
                                                break;
                                            }
                                            throw new Error("Recieved null airdrop transaction.");
                                        case 2:
                                            _ctx.next = 4;
                                            return parseAirdropTx(tx, context.options.connection);
                                        case 4:
                                            parsedAirdrop = _ctx.sent;
                                            if (!(collectionTokenAccount.toBase58() !== parsedAirdrop.destination.toBase58())) {
                                                _ctx.next = 7;
                                                break;
                                            }
                                            throw new Error("Collection token account and transaction destination mismatch.");
                                        case 7:
                                            return _ctx.abrupt("return", {
                                                airdropAmount: parsedAirdrop.airdropAmount,
                                                source: parsedAirdrop.source
                                            });
                                        case 8:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function(tx) {
                                return _ref.apply(this, arguments);
                            };
                        }());
                        _ctx1.next = 17;
                        return Promise.all(parsedAirdrops);
                    case 17:
                        airdrops = _ctx1.sent;
                        return _ctx1.abrupt("return", airdrops);
                    case 19:
                    case "end":
                        return _ctx1.stop();
                }
            }, _callee1);
        }));
        return function findAllByMint(_) {
            return _ref1.apply(this, arguments);
        };
    }();
    return {
        airdrop: airdrop,
        findVolumeByMint: findVolumeByMint,
        findAllByMint: findAllByMint
    };
};
