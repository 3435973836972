function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _iterableToArray(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _nonIterableSpread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { PublicKey } from "@solana/web3.js";
export var fetchAllTransactionSignaturesForAccount = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(connection, accountId) {
        var allSignatures, accountKey, signatures, _allSignatures, options;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    allSignatures = [];
                    accountKey = typeof accountId === "string" ? new PublicKey(accountId) : accountId;
                    signatures = [];
                case 3:
                    ;
                    options = signatures.length > 0 ? {
                        before: signatures[signatures.length - 1].signature
                    } : undefined;
                    _ctx.next = 7;
                    return connection.getSignaturesForAddress(accountKey, options);
                case 7:
                    signatures = _ctx.sent;
                    (_allSignatures = allSignatures).push.apply(_allSignatures, _toConsumableArray(signatures));
                case 9:
                    if (signatures.length > 0) {
                        _ctx.next = 3;
                        break;
                    }
                case 10:
                    return _ctx.abrupt("return", allSignatures);
                case 11:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function fetchAllTransactionSignaturesForAccount(connection, accountId) {
        return _ref.apply(this, arguments);
    };
}();
export var existsInTransactionLogs = function(transaction, substring) {
    var ref;
    var logMessages = (ref = transaction.meta) === null || ref === void 0 ? void 0 : ref.logMessages;
    if (!logMessages) {
        return false;
    }
    var exists = false;
    logMessages.forEach(function(line) {
        if (line.includes(substring)) {
            exists = true;
        }
    });
    return exists;
};
export var getAllTransactionsForAccount = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(address, connection) {
        var transactionSignatures, getAllTransactionsPromise;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return fetchAllTransactionSignaturesForAccount(connection, address);
                case 2:
                    transactionSignatures = _ctx.sent;
                    getAllTransactionsPromise = transactionSignatures.map(function(param) {
                        var signature = param.signature;
                        return connection.getTransaction(signature);
                    });
                    _ctx.next = 6;
                    return Promise.all(getAllTransactionsPromise);
                case 6:
                    return _ctx.abrupt("return", _ctx.sent);
                case 7:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function getAllTransactionsForAccount(address, connection) {
        return _ref.apply(this, arguments);
    };
}();
export var findAccountKeyIndexForAddress = function(transaction, address) {
    var index = transaction.transaction.message.accountKeys.findIndex(function(pubkey) {
        return pubkey.toBase58() === address.toBase58();
    });
    if (index === -1) {
        return null;
    }
    return index;
};
export var findInnerInstructionsForProgramAddress = function(transaction, programAddress) {
    var ref;
    var tokenProgramAccountKeyIndex = findAccountKeyIndexForAddress(transaction, programAddress);
    var ref1;
    var innerInstructions = (ref1 = (ref = transaction.meta) === null || ref === void 0 ? void 0 : ref.innerInstructions) !== null && ref1 !== void 0 ? ref1 : [];
    var result = [];
    innerInstructions.forEach(function(instruction) {
        instruction.instructions.forEach(function(ix) {
            if (ix.programIdIndex === tokenProgramAccountKeyIndex) {
                result.push(ix);
            }
        });
    });
    return result;
};
