function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { BN } from "@project-serum/anchor";
import { getAssociatedTokenAddress } from "@solana/spl-token";
import { SYSVAR_INSTRUCTIONS_PUBKEY, SYSVAR_SLOT_HASHES_PUBKEY, Transaction } from "@solana/web3.js";
import { CATAPULT_PROGRAM_ID } from "../constants";
import { findAssociatedTokenAccountAddress, findAtaCustodiedTokenPayload, findCatapult, findCollectionNumbering, findItemNumber, findItemNumberAttribution, findLaunch, findMetadataAddress, findPayloadMemo } from "../utils";
import bs58 from "bs58";
var createCatapultModule = function(context) {
    return function() {
        var _ref1 = _asyncToGenerator(regeneratorRuntime.mark(function _callee1(collection, wallet) {
            var collectionMetadata, collectionNumbering, catapult, registerCollection, registerItem, launchNativePayload, launchTokenPayload, isReadyForLanding, land, getLaunchFeed, fetchWalletData, getAllItemsForRegistration, claim, _groupPayloadsByLaunchIndex, _fetchAllPayloadMemosBySourceWallet, _fetchNftMetadataFromLandedOn, _nextLaunchKey, _nextLaunchIndex, _fetchNumLaunches, _fetchAllLaunchKeys, _fetchAllPayloadMemosFromLaunch, _fetchAllLaunchesLandedOnItem, _fetchAllRegisteredNftsByWalletOwner, _filterNftsByRegistered, _formatPayload, _calculateTimestampFromSlot, _formatLaunch;
            return regeneratorRuntime.wrap(function _callee$(_ctx1) {
                while(1)switch(_ctx1.prev = _ctx1.next){
                    case 0:
                        _ctx1.next = 2;
                        return findMetadataAddress(collection);
                    case 2:
                        collectionMetadata = _ctx1.sent;
                        _ctx1.next = 5;
                        return findCollectionNumbering(collection);
                    case 5:
                        collectionNumbering = _ctx1.sent;
                        _ctx1.next = 8;
                        return findCatapult(collectionNumbering);
                    case 8:
                        catapult = _ctx1.sent;
                        registerCollection = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
                                var createCollectionNumbering, createCatapult, tx, signature;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            _ctx.next = 2;
                                            return context.catapultProgram.methods.createCollectionNumbering().accounts({
                                                payer: context.provider.wallet.publicKey,
                                                collectionMetadata: collectionMetadata,
                                                collectionMint: collection,
                                                collectionNumbering: collectionNumbering
                                            }).instruction();
                                        case 2:
                                            createCollectionNumbering = _ctx.sent;
                                            _ctx.next = 5;
                                            return context.catapultProgram.methods.createCatapult().accounts({
                                                payer: context.provider.wallet.publicKey,
                                                collectionNumbering: collectionNumbering,
                                                catapult: catapult
                                            }).instruction();
                                        case 5:
                                            createCatapult = _ctx.sent;
                                            tx = new Transaction();
                                            tx.add(createCollectionNumbering);
                                            tx.add(createCatapult);
                                            _ctx.next = 11;
                                            return context.provider.send(tx);
                                        case 11:
                                            signature = _ctx.sent;
                                            return _ctx.abrupt("return", {
                                                signature: signature
                                            });
                                        case 13:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function registerCollection() {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        registerItem = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(item) {
                                var nft, itemNumber, collectionNumberingMetadata, numItems, itemNumberAttribution, incrementNumbering, tx, signature;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            _ctx.next = 2;
                                            return context.metaplex.nfts().findByMint(item);
                                        case 2:
                                            nft = _ctx.sent;
                                            _ctx.next = 5;
                                            return findItemNumber(collectionNumbering, item);
                                        case 5:
                                            itemNumber = _ctx.sent;
                                            _ctx.next = 8;
                                            return context.catapultProgram.account.collectionNumbering.fetch(collectionNumbering);
                                        case 8:
                                            collectionNumberingMetadata = _ctx.sent;
                                            numItems = collectionNumberingMetadata.numberedItems;
                                            _ctx.next = 12;
                                            return findItemNumberAttribution(collectionNumbering, new BN(numItems));
                                        case 12:
                                            itemNumberAttribution = _ctx.sent;
                                            _ctx.next = 15;
                                            return context.catapultProgram.methods.addItemToCollectionNumbering().accounts({
                                                payer: context.provider.wallet.publicKey,
                                                collectionNumbering: collectionNumbering,
                                                itemMetadata: nft.metadataAccount.publicKey,
                                                itemNumber: itemNumber,
                                                itemNumberAttribution: itemNumberAttribution
                                            }).instruction();
                                        case 15:
                                            incrementNumbering = _ctx.sent;
                                            tx = new Transaction();
                                            tx.add(incrementNumbering);
                                            _ctx.next = 20;
                                            return context.provider.send(tx);
                                        case 20:
                                            signature = _ctx.sent;
                                            return _ctx.abrupt("return", {
                                                signature: signature
                                            });
                                        case 22:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function registerItem(item) {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        launchNativePayload = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(amount, message) {
                                var nextLaunch, payloadMemo, createNextLaunch, loadNativePayload, fire, tx, signature;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            _ctx.next = 2;
                                            return _nextLaunchKey();
                                        case 2:
                                            nextLaunch = _ctx.sent;
                                            _ctx.next = 5;
                                            return findPayloadMemo(nextLaunch, 0);
                                        case 5:
                                            payloadMemo = _ctx.sent;
                                            _ctx.next = 8;
                                            return context.catapultProgram.methods.createNextLaunch().accounts({
                                                payer: context.provider.wallet.publicKey,
                                                catapult: catapult,
                                                launch: nextLaunch
                                            }).instruction();
                                        case 8:
                                            createNextLaunch = _ctx.sent;
                                            _ctx.next = 11;
                                            return context.catapultProgram.methods.loadNativePayload({
                                                message: message !== null && message !== void 0 ? message : "",
                                                amount: new BN(amount)
                                            }).accounts({
                                                payer: context.provider.wallet.publicKey,
                                                payloadProvider: context.provider.wallet.publicKey,
                                                catapult: catapult,
                                                launch: nextLaunch,
                                                payloadMemo: payloadMemo
                                            }).instruction();
                                        case 11:
                                            loadNativePayload = _ctx.sent;
                                            _ctx.next = 14;
                                            return context.catapultProgram.methods.fire().accounts({
                                                payer: context.provider.wallet.publicKey,
                                                numbering: collectionNumbering,
                                                catapult: catapult,
                                                launch: nextLaunch,
                                                slotHashes: SYSVAR_SLOT_HASHES_PUBKEY,
                                                instructions: SYSVAR_INSTRUCTIONS_PUBKEY
                                            }).instruction();
                                        case 14:
                                            fire = _ctx.sent;
                                            tx = new Transaction();
                                            tx.add(createNextLaunch);
                                            tx.add(loadNativePayload);
                                            tx.add(fire);
                                            _ctx.next = 21;
                                            return context.provider.send(tx);
                                        case 21:
                                            signature = _ctx.sent;
                                            return _ctx.abrupt("return", {
                                                signature: signature,
                                                launch: nextLaunch
                                            });
                                        case 23:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function launchNativePayload(amount, message) {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        launchTokenPayload = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(splToken, amount, message) {
                                var nextLaunch, payloadMemo, inputTokenPayload, custodiedTokenPayload, createNextLaunch, loadTokenPayload, fire, tx, signature;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            _ctx.next = 2;
                                            return _nextLaunchKey();
                                        case 2:
                                            nextLaunch = _ctx.sent;
                                            _ctx.next = 5;
                                            return findPayloadMemo(nextLaunch, 0);
                                        case 5:
                                            payloadMemo = _ctx.sent;
                                            _ctx.next = 8;
                                            return getAssociatedTokenAddress(splToken, context.provider.wallet.publicKey);
                                        case 8:
                                            inputTokenPayload = _ctx.sent;
                                            _ctx.next = 11;
                                            return findAtaCustodiedTokenPayload(splToken, nextLaunch);
                                        case 11:
                                            custodiedTokenPayload = _ctx.sent;
                                            _ctx.next = 14;
                                            return context.catapultProgram.methods.createNextLaunch().accounts({
                                                payer: context.provider.wallet.publicKey,
                                                catapult: catapult,
                                                launch: nextLaunch
                                            }).instruction();
                                        case 14:
                                            createNextLaunch = _ctx.sent;
                                            _ctx.next = 17;
                                            return context.catapultProgram.methods.loadTokenPayload({
                                                message: message !== null && message !== void 0 ? message : "",
                                                amount: new BN(amount)
                                            }).accounts({
                                                payer: context.provider.wallet.publicKey,
                                                payloadProvider: context.provider.wallet.publicKey,
                                                inputTokenPayload: inputTokenPayload,
                                                payloadMint: splToken,
                                                custodiedTokenPayload: custodiedTokenPayload,
                                                catapult: catapult,
                                                launch: nextLaunch,
                                                payloadMemo: payloadMemo
                                            }).instruction();
                                        case 17:
                                            loadTokenPayload = _ctx.sent;
                                            _ctx.next = 20;
                                            return context.catapultProgram.methods.fire().accounts({
                                                payer: context.provider.wallet.publicKey,
                                                numbering: collectionNumbering,
                                                catapult: catapult,
                                                launch: nextLaunch,
                                                slotHashes: SYSVAR_SLOT_HASHES_PUBKEY,
                                                instructions: SYSVAR_INSTRUCTIONS_PUBKEY
                                            }).instruction();
                                        case 20:
                                            fire = _ctx.sent;
                                            tx = new Transaction();
                                            tx.add(createNextLaunch);
                                            tx.add(loadTokenPayload);
                                            tx.add(fire);
                                            _ctx.next = 27;
                                            return context.provider.send(tx);
                                        case 27:
                                            signature = _ctx.sent;
                                            return _ctx.abrupt("return", {
                                                signature: signature,
                                                launch: nextLaunch
                                            });
                                        case 29:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function launchTokenPayload(splToken, amount, message) {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        isReadyForLanding = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(launch) {
                                var launchData, desiredSlot, currentSlot;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            _ctx.next = 2;
                                            return context.catapultProgram.account.launch.fetch(launch);
                                        case 2:
                                            launchData = _ctx.sent;
                                            desiredSlot = launchData.landingSlot.toNumber();
                                            _ctx.next = 6;
                                            return context.provider.connection.getSlot();
                                        case 6:
                                            currentSlot = _ctx.sent;
                                            return _ctx.abrupt("return", currentSlot > desiredSlot);
                                        case 8:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function isReadyForLanding(launch) {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        land = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(launch) {
                                var isReady, landLaunch, tx, signature;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            _ctx.next = 2;
                                            return isReadyForLanding(launch);
                                        case 2:
                                            isReady = _ctx.sent;
                                            if (isReady) {
                                                _ctx.next = 5;
                                                break;
                                            }
                                            throw new Error("Slot requirement has not been met.");
                                        case 5:
                                            _ctx.next = 7;
                                            return context.catapultProgram.methods.land().accounts({
                                                payer: context.provider.wallet.publicKey,
                                                launch: launch,
                                                slotHashes: SYSVAR_SLOT_HASHES_PUBKEY,
                                                instructions: SYSVAR_INSTRUCTIONS_PUBKEY
                                            }).instruction();
                                        case 7:
                                            landLaunch = _ctx.sent;
                                            tx = new Transaction();
                                            tx.add(landLaunch);
                                            _ctx.next = 12;
                                            return context.provider.send(tx);
                                        case 12:
                                            signature = _ctx.sent;
                                            return _ctx.abrupt("return", {
                                                signature: signature
                                            });
                                        case 14:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function land(launch) {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        getLaunchFeed = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
                                var launchItems, launches, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, launch, payloads, launchMetadata, formattedLaunch;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            launchItems = [];
                                            _ctx.next = 3;
                                            return _fetchAllLaunchKeys();
                                        case 3:
                                            launches = _ctx.sent;
                                            _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                                            _ctx.prev = 5;
                                            _iterator = launches[Symbol.iterator]();
                                        case 7:
                                            if (_iteratorNormalCompletion = (_step = _iterator.next()).done) {
                                                _ctx.next = 22;
                                                break;
                                            }
                                            launch = _step.value;
                                            _ctx.next = 11;
                                            return _fetchAllPayloadMemosFromLaunch(launch);
                                        case 11:
                                            payloads = _ctx.sent;
                                            _ctx.next = 14;
                                            return context.catapultProgram.account.launch.fetch(launch);
                                        case 14:
                                            launchMetadata = _ctx.sent;
                                            _ctx.next = 17;
                                            return _formatLaunch(launchMetadata, payloads);
                                        case 17:
                                            formattedLaunch = _ctx.sent;
                                            launchItems.push(formattedLaunch);
                                        case 19:
                                            _iteratorNormalCompletion = true;
                                            _ctx.next = 7;
                                            break;
                                        case 22:
                                            _ctx.next = 28;
                                            break;
                                        case 24:
                                            _ctx.prev = 24;
                                            _ctx.t0 = _ctx["catch"](5);
                                            _didIteratorError = true;
                                            _iteratorError = _ctx.t0;
                                        case 28:
                                            _ctx.prev = 28;
                                            _ctx.prev = 29;
                                            if (!_iteratorNormalCompletion && _iterator.return != null) {
                                                _iterator.return();
                                            }
                                        case 31:
                                            _ctx.prev = 31;
                                            if (!_didIteratorError) {
                                                _ctx.next = 34;
                                                break;
                                            }
                                            throw _iteratorError;
                                        case 34:
                                            return _ctx.finish(31);
                                        case 35:
                                            return _ctx.finish(28);
                                        case 36:
                                            return _ctx.abrupt("return", launchItems.sort(function(a, b) {
                                                return b.estimatedTimestamp - a.estimatedTimestamp;
                                            }));
                                        case 37:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee, null, [
                                    [
                                        5,
                                        24,
                                        28,
                                        36
                                    ],
                                    [
                                        29,
                                        ,
                                        31,
                                        35
                                    ]
                                ]);
                            }));
                            return function getLaunchFeed() {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        fetchWalletData = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(walletAddress) {
                                var walletToUse, registeredNfts, walletData, i, nft, launches, lands, j, launch, payloads, launchData, formattedLaunch, payloadsFromWallet, groupedPayloads, launchIndexes, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, launchIndex, bnLaunchIndex, launch1, payloads1, launchData1, formattedLaunch1;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            if (!(!walletAddress && !wallet)) {
                                                _ctx.next = 2;
                                                break;
                                            }
                                            throw new Error("No wallet provided.");
                                        case 2:
                                            walletToUse = walletAddress ? walletAddress : wallet;
                                            _ctx.next = 5;
                                            return _fetchAllRegisteredNftsByWalletOwner(walletToUse);
                                        case 5:
                                            registeredNfts = _ctx.sent;
                                            walletData = {
                                                registered: [],
                                                catapults: []
                                            };
                                            i = 0;
                                        case 8:
                                            if (!(i < registeredNfts.length)) {
                                                _ctx.next = 36;
                                                break;
                                            }
                                            nft = registeredNfts[i];
                                            _ctx.next = 12;
                                            return _fetchAllLaunchesLandedOnItem(nft.mint);
                                        case 12:
                                            launches = _ctx.sent;
                                            lands = [];
                                            j = 0;
                                        case 15:
                                            if (!(j < launches.length)) {
                                                _ctx.next = 30;
                                                break;
                                            }
                                            launch = launches[j];
                                            _ctx.next = 19;
                                            return _fetchAllPayloadMemosFromLaunch(launch);
                                        case 19:
                                            payloads = _ctx.sent;
                                            _ctx.next = 22;
                                            return context.catapultProgram.account.launch.fetch(launch);
                                        case 22:
                                            launchData = _ctx.sent;
                                            _ctx.next = 25;
                                            return _formatLaunch(launchData, payloads);
                                        case 25:
                                            formattedLaunch = _ctx.sent;
                                            lands.push(formattedLaunch);
                                        case 27:
                                            j++;
                                            _ctx.next = 15;
                                            break;
                                        case 30:
                                            _ctx.next = 32;
                                            return nft.metadataTask.run();
                                        case 32:
                                            walletData.registered.push({
                                                data: nft,
                                                lands: lands
                                            });
                                        case 33:
                                            i++;
                                            _ctx.next = 8;
                                            break;
                                        case 36:
                                            _ctx.next = 38;
                                            return _fetchAllPayloadMemosBySourceWallet(walletToUse);
                                        case 38:
                                            payloadsFromWallet = _ctx.sent;
                                            groupedPayloads = _groupPayloadsByLaunchIndex(payloadsFromWallet);
                                            launchIndexes = Object.keys(groupedPayloads);
                                            _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                                            _ctx.prev = 42;
                                            _iterator = launchIndexes[Symbol.iterator]();
                                        case 44:
                                            if (_iteratorNormalCompletion = (_step = _iterator.next()).done) {
                                                _ctx.next = 61;
                                                break;
                                            }
                                            launchIndex = _step.value;
                                            bnLaunchIndex = new BN(parseInt(launchIndex));
                                            _ctx.next = 49;
                                            return findLaunch(catapult, bnLaunchIndex);
                                        case 49:
                                            launch1 = _ctx.sent;
                                            payloads1 = groupedPayloads[launchIndex];
                                            _ctx.next = 53;
                                            return context.catapultProgram.account.launch.fetch(launch1);
                                        case 53:
                                            launchData1 = _ctx.sent;
                                            _ctx.next = 56;
                                            return _formatLaunch(launchData1, payloads1);
                                        case 56:
                                            formattedLaunch1 = _ctx.sent;
                                            walletData.catapults.push(formattedLaunch1);
                                        case 58:
                                            _iteratorNormalCompletion = true;
                                            _ctx.next = 44;
                                            break;
                                        case 61:
                                            _ctx.next = 67;
                                            break;
                                        case 63:
                                            _ctx.prev = 63;
                                            _ctx.t0 = _ctx["catch"](42);
                                            _didIteratorError = true;
                                            _iteratorError = _ctx.t0;
                                        case 67:
                                            _ctx.prev = 67;
                                            _ctx.prev = 68;
                                            if (!_iteratorNormalCompletion && _iterator.return != null) {
                                                _iterator.return();
                                            }
                                        case 70:
                                            _ctx.prev = 70;
                                            if (!_didIteratorError) {
                                                _ctx.next = 73;
                                                break;
                                            }
                                            throw _iteratorError;
                                        case 73:
                                            return _ctx.finish(70);
                                        case 74:
                                            return _ctx.finish(67);
                                        case 75:
                                            return _ctx.abrupt("return", walletData);
                                        case 76:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee, null, [
                                    [
                                        42,
                                        63,
                                        67,
                                        75
                                    ],
                                    [
                                        68,
                                        ,
                                        70,
                                        74
                                    ]
                                ]);
                            }));
                            return function fetchWalletData(walletAddress) {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        getAllItemsForRegistration = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(walletAddress) {
                                var walletToUse, nfts, nftsForCollection, allRegistered, allRegisteredPubkeys, nonRegistered;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            if (!(!walletAddress && !wallet)) {
                                                _ctx.next = 2;
                                                break;
                                            }
                                            throw new Error("No wallet provided.");
                                        case 2:
                                            walletToUse = walletAddress ? walletAddress : wallet;
                                            if (walletToUse) {
                                                _ctx.next = 5;
                                                break;
                                            }
                                            throw new Error("No wallet provided.");
                                        case 5:
                                            _ctx.next = 7;
                                            return context.metaplex.nfts().findAllByOwner(walletToUse);
                                        case 7:
                                            nfts = _ctx.sent;
                                            nftsForCollection = nfts.filter(function(nft) {
                                                var ref;
                                                return ((ref = nft.collection) === null || ref === void 0 ? void 0 : ref.key.toBase58()) === collection.toBase58();
                                            });
                                            _ctx.next = 11;
                                            return _filterNftsByRegistered(nftsForCollection);
                                        case 11:
                                            allRegistered = _ctx.sent;
                                            allRegisteredPubkeys = allRegistered.map(function(nft) {
                                                return nft.mint.toBase58();
                                            });
                                            nonRegistered = nftsForCollection.filter(function(nft) {
                                                return !allRegisteredPubkeys.some(function(registeredNft) {
                                                    return registeredNft === nft.mint.toBase58();
                                                });
                                            });
                                            return _ctx.abrupt("return", nonRegistered);
                                        case 15:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function getAllItemsForRegistration(walletAddress) {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        claim = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(launch, item, token) {
                                var claimantItemTokenAccount, itemNumber, claimIx, custodiedTokenPayload, claimantTokenPayload, tx, signature;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            _ctx.next = 2;
                                            return findAssociatedTokenAccountAddress(context.options.wallet.publicKey, item);
                                        case 2:
                                            claimantItemTokenAccount = _ctx.sent;
                                            _ctx.next = 5;
                                            return findItemNumber(collectionNumbering, item);
                                        case 5:
                                            itemNumber = _ctx.sent;
                                            ;
                                            if (!token) {
                                                _ctx.next = 19;
                                                break;
                                            }
                                            _ctx.next = 10;
                                            return findAtaCustodiedTokenPayload(token, launch);
                                        case 10:
                                            custodiedTokenPayload = _ctx.sent;
                                            _ctx.next = 13;
                                            return findAssociatedTokenAccountAddress(context.options.wallet.publicKey, token);
                                        case 13:
                                            claimantTokenPayload = _ctx.sent;
                                            _ctx.next = 16;
                                            return context.catapultProgram.methods.claimTokenPayload().accounts({
                                                claimant: context.options.wallet.publicKey,
                                                claimantItemTokenAccount: claimantItemTokenAccount,
                                                itemNumber: itemNumber,
                                                claimantTokenPayload: claimantTokenPayload,
                                                custodiedTokenPayload: custodiedTokenPayload,
                                                collectionNumbering: collectionNumbering,
                                                catapult: catapult,
                                                launch: launch
                                            }).instruction();
                                        case 16:
                                            claimIx = _ctx.sent;
                                            _ctx.next = 22;
                                            break;
                                        case 19:
                                            _ctx.next = 21;
                                            return context.catapultProgram.methods.claimNativePayload().accounts({
                                                claimant: context.options.wallet.publicKey,
                                                claimantItemTokenAccount: claimantItemTokenAccount,
                                                itemNumber: itemNumber,
                                                collectionNumbering: collectionNumbering,
                                                catapult: catapult,
                                                launch: launch
                                            }).instruction();
                                        case 21:
                                            claimIx = _ctx.sent;
                                        case 22:
                                            tx = new Transaction();
                                            tx.add(claimIx);
                                            _ctx.next = 26;
                                            return context.provider.send(tx);
                                        case 26:
                                            signature = _ctx.sent;
                                            return _ctx.abrupt("return", {
                                                signature: signature
                                            });
                                        case 28:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function claim(launch, item, token) {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        _groupPayloadsByLaunchIndex = function(payloads) {
                            return payloads.reduce(function(result, currentValue) {
                                var key = currentValue.launchIndex.toNumber();
                                if (!result[key]) {
                                    result[key] = [];
                                }
                                result[key].push(currentValue);
                                return result;
                            }, {});
                        };
                        _fetchAllPayloadMemosBySourceWallet = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(walletAddress) {
                                var walletToUse, bytes, offset, config, responses, memos;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            if (!(!walletAddress && !wallet)) {
                                                _ctx.next = 2;
                                                break;
                                            }
                                            throw new Error("No wallet provided.");
                                        case 2:
                                            walletToUse = walletAddress ? walletAddress : wallet;
                                            if (walletToUse) {
                                                _ctx.next = 5;
                                                break;
                                            }
                                            throw new Error("No wallet provided.");
                                        case 5:
                                            bytes = bs58.encode(Buffer.concat([
                                                catapult.toBuffer(),
                                                walletToUse.toBuffer()
                                            ]));
                                            offset = 8;
                                            config = {
                                                filters: [
                                                    {
                                                        memcmp: {
                                                            bytes: bytes,
                                                            offset: offset
                                                        }
                                                    }, 
                                                ]
                                            };
                                            _ctx.next = 10;
                                            return context.provider.connection.getProgramAccounts(CATAPULT_PROGRAM_ID, config);
                                        case 10:
                                            responses = _ctx.sent;
                                            memos = responses.map(function(response) {
                                                return {
                                                    key: response.pubkey,
                                                    data: context.catapultProgram.coder.accounts.decode("payloadMemo", response.account.data)
                                                };
                                            });
                                            return _ctx.abrupt("return", memos.map(function(memo) {
                                                return memo.data;
                                            }));
                                        case 13:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function _fetchAllPayloadMemosBySourceWallet(walletAddress) {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        _fetchNftMetadataFromLandedOn = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(landedOn) {
                                var winner, itemNumberAttribution, itemNumberAttributionMetadata;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            winner = undefined;
                                            if (!(landedOn !== undefined && landedOn !== null)) {
                                                _ctx.next = 11;
                                                break;
                                            }
                                            _ctx.next = 4;
                                            return findItemNumberAttribution(collectionNumbering, landedOn);
                                        case 4:
                                            itemNumberAttribution = _ctx.sent;
                                            _ctx.next = 7;
                                            return context.catapultProgram.account.itemNumberAttribution.fetch(itemNumberAttribution);
                                        case 7:
                                            itemNumberAttributionMetadata = _ctx.sent;
                                            _ctx.next = 10;
                                            return context.metaplex.nfts().findByMint(itemNumberAttributionMetadata.mintKey);
                                        case 10:
                                            winner = _ctx.sent;
                                        case 11:
                                            return _ctx.abrupt("return", winner);
                                        case 12:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function _fetchNftMetadataFromLandedOn(landedOn) {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        _nextLaunchKey = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
                                var nextIndex;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            _ctx.next = 2;
                                            return _nextLaunchIndex();
                                        case 2:
                                            nextIndex = _ctx.sent;
                                            return _ctx.abrupt("return", findLaunch(catapult, nextIndex));
                                        case 4:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function _nextLaunchKey() {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        _nextLaunchIndex = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
                                var catapultInfo;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            _ctx.next = 2;
                                            return context.catapultProgram.account.catapult.fetch(catapult);
                                        case 2:
                                            catapultInfo = _ctx.sent;
                                            return _ctx.abrupt("return", catapultInfo.numLaunches);
                                        case 4:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function _nextLaunchIndex() {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        _fetchNumLaunches = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
                                var catapultMetadata;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            _ctx.next = 2;
                                            return context.catapultProgram.account.catapult.fetch(catapult);
                                        case 2:
                                            catapultMetadata = _ctx.sent;
                                            return _ctx.abrupt("return", catapultMetadata.numLaunches.toNumber());
                                        case 4:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function _fetchNumLaunches() {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        _fetchAllLaunchKeys = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
                                var numLaunches, promises, i;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            _ctx.next = 2;
                                            return _fetchNumLaunches();
                                        case 2:
                                            numLaunches = _ctx.sent;
                                            promises = [];
                                            for(i = 0; i < numLaunches; i++){
                                                promises.push(findLaunch(catapult, new BN(i)));
                                            }
                                            return _ctx.abrupt("return", Promise.all(promises));
                                        case 6:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function _fetchAllLaunchKeys() {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        _fetchAllPayloadMemosFromLaunch = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(launch) {
                                var launchMetadata, numPayloads, promises, i, key;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            _ctx.next = 2;
                                            return context.catapultProgram.account.launch.fetch(launch);
                                        case 2:
                                            launchMetadata = _ctx.sent;
                                            numPayloads = launchMetadata.numPayloads;
                                            promises = [];
                                            i = numPayloads - 1;
                                        case 6:
                                            if (!(i > -1)) {
                                                _ctx.next = 14;
                                                break;
                                            }
                                            _ctx.next = 9;
                                            return findPayloadMemo(launch, i);
                                        case 9:
                                            key = _ctx.sent;
                                            promises.push(context.catapultProgram.account.payloadMemo.fetch(key));
                                        case 11:
                                            i--;
                                            _ctx.next = 6;
                                            break;
                                        case 14:
                                            return _ctx.abrupt("return", Promise.all(promises));
                                        case 15:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function _fetchAllPayloadMemosFromLaunch(launch) {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        _fetchAllLaunchesLandedOnItem = function() {
                            var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(mint) {
                                var itemNumberAddress, itemNumberMetadata, itemNumber, config, responses, launches, filteredLaunches;
                                return regeneratorRuntime.wrap(function _callee$(_ctx2) {
                                    while(1)switch(_ctx2.prev = _ctx2.next){
                                        case 0:
                                            _ctx2.next = 2;
                                            return findItemNumber(collectionNumbering, mint);
                                        case 2:
                                            itemNumberAddress = _ctx2.sent;
                                            _ctx2.next = 5;
                                            return context.catapultProgram.account.itemNumber.fetch(itemNumberAddress);
                                        case 5:
                                            itemNumberMetadata = _ctx2.sent;
                                            itemNumber = itemNumberMetadata.value.toNumber();
                                            config = {
                                                filters: [
                                                    {
                                                        memcmp: {
                                                            bytes: bs58.encode(Buffer.concat([
                                                                new BN(1).toArrayLike(Buffer, "le", 1),
                                                                new BN(itemNumber).toArrayLike(Buffer, "le", 8), 
                                                            ], 9)),
                                                            offset: 25
                                                        }
                                                    }, 
                                                ]
                                            };
                                            _ctx2.next = 10;
                                            return context.provider.connection.getProgramAccounts(CATAPULT_PROGRAM_ID, config);
                                        case 10:
                                            responses = _ctx2.sent;
                                            launches = responses.map(function(response) {
                                                return {
                                                    key: response.pubkey,
                                                    data: context.catapultProgram.coder.accounts.decode("launch", response.account.data)
                                                };
                                            });
                                            filteredLaunches = [];
                                            launches.map(function() {
                                                var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(launch) {
                                                    return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                                        while(1)switch(_ctx.prev = _ctx.next){
                                                            case 0:
                                                                if (itemNumberMetadata.value.toNumber() === launch.data.landedOn.toNumber()) {
                                                                    filteredLaunches.push(launch.key);
                                                                }
                                                            case 1:
                                                            case "end":
                                                                return _ctx.stop();
                                                        }
                                                    }, _callee);
                                                }));
                                                return function(launch) {
                                                    return _ref.apply(this, arguments);
                                                };
                                            }());
                                            return _ctx2.abrupt("return", filteredLaunches);
                                        case 15:
                                        case "end":
                                            return _ctx2.stop();
                                    }
                                }, _callee2);
                            }));
                            return function _fetchAllLaunchesLandedOnItem(mint) {
                                return _ref2.apply(this, arguments);
                            };
                        }();
                        _fetchAllRegisteredNftsByWalletOwner = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(walletAddress) {
                                var allNfts, allNftsForCollection, allRegisteredNfts;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            _ctx.next = 2;
                                            return context.metaplex.nfts().findAllByOwner(walletAddress);
                                        case 2:
                                            allNfts = _ctx.sent;
                                            allNftsForCollection = allNfts.filter(function(nft) {
                                                var ref, ref1, ref2;
                                                return ((ref = nft.collection) === null || ref === void 0 ? void 0 : ref.verified) === true && ((ref1 = nft.collection) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.key) === null || ref2 === void 0 ? void 0 : ref2.toBase58()) === collection.toBase58();
                                            });
                                            _ctx.next = 6;
                                            return _filterNftsByRegistered(allNftsForCollection);
                                        case 6:
                                            allRegisteredNfts = _ctx.sent;
                                            return _ctx.abrupt("return", allRegisteredNfts);
                                        case 8:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function _fetchAllRegisteredNftsByWalletOwner(walletAddress) {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        _filterNftsByRegistered = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(nfts) {
                                var filteredNfts, i, nft, itemNumberAddress;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            filteredNfts = [];
                                            i = 0;
                                        case 2:
                                            if (!(i < nfts.length)) {
                                                _ctx.next = 19;
                                                break;
                                            }
                                            nft = nfts[i];
                                            _ctx.next = 6;
                                            return findItemNumber(collectionNumbering, nft.mint);
                                        case 6:
                                            itemNumberAddress = _ctx.sent;
                                            _ctx.prev = 7;
                                            _ctx.next = 10;
                                            return context.catapultProgram.account.itemNumber.fetch(itemNumberAddress);
                                        case 10:
                                            filteredNfts.push(nft);
                                            _ctx.next = 16;
                                            break;
                                        case 13:
                                            _ctx.prev = 13;
                                            _ctx.t0 = _ctx["catch"](7);
                                            return _ctx.abrupt("continue", 16);
                                        case 16:
                                            i++;
                                            _ctx.next = 2;
                                            break;
                                        case 19:
                                            return _ctx.abrupt("return", filteredNfts);
                                        case 20:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee, null, [
                                    [
                                        7,
                                        13
                                    ]
                                ]);
                            }));
                            return function _filterNftsByRegistered(nfts) {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        _formatPayload = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(payload, mint) {
                                var nftMetadata;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            if (!mint) {
                                                _ctx.next = 13;
                                                break;
                                            }
                                            nftMetadata = undefined;
                                            _ctx.prev = 2;
                                            _ctx.next = 5;
                                            return context.metaplex.nfts().findByMint(mint);
                                        case 5:
                                            nftMetadata = _ctx.sent;
                                            _ctx.next = 10;
                                            break;
                                        case 8:
                                            _ctx.prev = 8;
                                            _ctx.t0 = _ctx["catch"](2);
                                        case 10:
                                            return _ctx.abrupt("return", {
                                                isNativeSol: false,
                                                mint: mint,
                                                amount: payload.amount.toNumber(),
                                                message: payload.message,
                                                nftMetadata: nftMetadata
                                            });
                                        case 13:
                                            return _ctx.abrupt("return", {
                                                isNativeSol: true,
                                                amount: payload.amount.toNumber(),
                                                message: payload.message
                                            });
                                        case 14:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee, null, [
                                    [
                                        2,
                                        8
                                    ]
                                ]);
                            }));
                            return function _formatPayload(payload, mint) {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        _calculateTimestampFromSlot = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(slot) {
                                var currentSlot, slotDiff, slotDiffInMilliseconds, now, date;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            _ctx.next = 2;
                                            return context.provider.connection.getSlot();
                                        case 2:
                                            currentSlot = _ctx.sent;
                                            slotDiff = currentSlot - slot;
                                            slotDiffInMilliseconds = slotDiff * 400; // 400ms per slot
                                            now = new Date();
                                            date = new Date(now.valueOf() - slotDiffInMilliseconds);
                                            return _ctx.abrupt("return", date.getTime());
                                        case 8:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function _calculateTimestampFromSlot(slot) {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        _formatLaunch = function() {
                            var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(launch, payloads) {
                                var winner, launchPublicKey, formattedPayloads, payloadIndex, payload, mint, payloadItem, estimatedTimestamp;
                                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                    while(1)switch(_ctx.prev = _ctx.next){
                                        case 0:
                                            _ctx.next = 2;
                                            return _fetchNftMetadataFromLandedOn(launch.landedOn);
                                        case 2:
                                            winner = _ctx.sent;
                                            _ctx.next = 5;
                                            return findLaunch(catapult, new BN(launch.index));
                                        case 5:
                                            launchPublicKey = _ctx.sent;
                                            if (!(payloads.length > 0)) {
                                                _ctx.next = 23;
                                                break;
                                            }
                                            formattedPayloads = [];
                                            payloadIndex = 0;
                                        case 9:
                                            if (!(payloadIndex < payloads.length)) {
                                                _ctx.next = 19;
                                                break;
                                            }
                                            payload = payloads[payloadIndex];
                                            mint = payload.mintIndex !== null ? launch.mints[payload.mintIndex] : undefined;
                                            _ctx.next = 14;
                                            return _formatPayload(payload, mint);
                                        case 14:
                                            payloadItem = _ctx.sent;
                                            formattedPayloads.push(payloadItem);
                                        case 16:
                                            payloadIndex++;
                                            _ctx.next = 9;
                                            break;
                                        case 19:
                                            _ctx.next = 21;
                                            return _calculateTimestampFromSlot(launch.landingSlot);
                                        case 21:
                                            estimatedTimestamp = _ctx.sent;
                                            return _ctx.abrupt("return", {
                                                launchIndex: launch.index,
                                                launchPublicKey: launchPublicKey,
                                                landedOnNumber: launch.landedOn,
                                                winnerNft: winner,
                                                source: payloads[0].sourceWallet,
                                                payloads: formattedPayloads,
                                                numClaims: launch.numClaims,
                                                estimatedTimestamp: estimatedTimestamp
                                            });
                                        case 23:
                                            throw new Error("No Payload associated with this launch");
                                        case 24:
                                        case "end":
                                            return _ctx.stop();
                                    }
                                }, _callee);
                            }));
                            return function _formatLaunch(launch, payloads) {
                                return _ref.apply(this, arguments);
                            };
                        }();
                        return _ctx1.abrupt("return", {
                            registerCollection: registerCollection,
                            registerItem: registerItem,
                            launchNativePayload: launchNativePayload,
                            launchTokenPayload: launchTokenPayload,
                            isReadyForLanding: isReadyForLanding,
                            land: land,
                            getLaunchFeed: getLaunchFeed,
                            fetchWalletData: fetchWalletData,
                            getAllItemsForRegistration: getAllItemsForRegistration,
                            claim: claim
                        });
                    case 34:
                    case "end":
                        return _ctx1.stop();
                }
            }, _callee1);
        }));
        return function(collection, wallet) {
            return _ref1.apply(this, arguments);
        };
    }();
};
export default createCatapultModule;
