import createAirdropModule from "./airdrop";
import createCollectionModule from "./collection";
import createClaimModule from "./claim";
import createNftModule from "./nft";
import createCatapultModule from "./catapult";
export default function(context) {
    return {
        airdrop: createAirdropModule(context),
        collection: createCollectionModule(context),
        claim: createClaimModule(context),
        nft: createNftModule(context),
        catapult: createCatapultModule(context)
    };
};
