function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _iterableToArrayLimit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
import regeneratorRuntime from "/vercel/path0/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import * as anchor from "@project-serum/anchor";
import { PublicKey } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { TOKEN_METADATA_PROGRAM_ID, FOLLOWER_COLLECTION_PROGRAM_ID, METADATA_SEED, EDITION_SEED, COLLECTION_PATROL_SEED, COLLECTION_DISTRIBUTOR_PROGRAM_ID, DROP_TOKENS_PROGRAM_ID, DROP_TOKENS_PATROL_SEED, MINT_REQUEST_PROGRAM_ID, CATAPULT_PROGRAM_ID } from "../constants";
import { bytes } from "@project-serum/anchor/dist/cjs/utils";
import * as SplToken from "@solana/spl-token";
export var findDropPatrolAddress = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(mint) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        anchor.utils.bytes.utf8.encode(DROP_TOKENS_PATROL_SEED),
                        mint.toBuffer()
                    ], DROP_TOKENS_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findDropPatrolAddress(mint) {
        return _ref.apply(this, arguments);
    };
}();
export var findCollectionPatrol = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(collectionMint) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        anchor.utils.bytes.utf8.encode(COLLECTION_PATROL_SEED),
                        collectionMint.toBuffer(), 
                    ], FOLLOWER_COLLECTION_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 2), address = _param[0], bump = _param[1];
                        return {
                            address: address,
                            bump: bump
                        };
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findCollectionPatrol(collectionMint) {
        return _ref.apply(this, arguments);
    };
}();
export var findAssociatedTokenAccountAddress = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(owner, mint) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        owner.toBuffer(),
                        TOKEN_PROGRAM_ID.toBuffer(),
                        mint.toBuffer()
                    ], ASSOCIATED_TOKEN_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findAssociatedTokenAccountAddress(owner, mint) {
        return _ref.apply(this, arguments);
    };
}();
export var findAssociatedTokenAccountPda = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(owner, mint) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        owner.toBuffer(),
                        TOKEN_PROGRAM_ID.toBuffer(),
                        mint.toBuffer()
                    ], ASSOCIATED_TOKEN_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 2), address = _param[0], bump = _param[1];
                        return {
                            address: address,
                            bump: bump
                        };
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findAssociatedTokenAccountPda(owner, mint) {
        return _ref.apply(this, arguments);
    };
}();
export var findMetadataAddress = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(mint) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        Buffer.from(METADATA_SEED),
                        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
                        mint.toBuffer(), 
                    ], TOKEN_METADATA_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findMetadataAddress(mint) {
        return _ref.apply(this, arguments);
    };
}();
export var findMetadataPda = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(mint) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        Buffer.from(METADATA_SEED),
                        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
                        mint.toBuffer(), 
                    ], TOKEN_METADATA_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 2), address = _param[0], bump = _param[1];
                        return {
                            address: address,
                            bump: bump
                        };
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findMetadataPda(mint) {
        return _ref.apply(this, arguments);
    };
}();
export var findMasterEditionAddress = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(mint) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        Buffer.from(METADATA_SEED),
                        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
                        mint.toBuffer(),
                        Buffer.from(EDITION_SEED), 
                    ], TOKEN_METADATA_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findMasterEditionAddress(mint) {
        return _ref.apply(this, arguments);
    };
}();
export var findMasterEditionPda = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(mint) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        Buffer.from(METADATA_SEED),
                        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
                        mint.toBuffer(),
                        Buffer.from(EDITION_SEED), 
                    ], TOKEN_METADATA_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 2), address = _param[0], bump = _param[1];
                        return {
                            address: address,
                            bump: bump
                        };
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findMasterEditionPda(mint) {
        return _ref.apply(this, arguments);
    };
}();
export var findMintRequestAddress = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(requestor, acceptor, collectionMint, redeemAuthority) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        bytes.utf8.encode("mint_request"),
                        requestor.toBuffer(),
                        acceptor.toBuffer(),
                        collectionMint.toBuffer(),
                        redeemAuthority.toBuffer(), 
                    ], MINT_REQUEST_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findMintRequestAddress(requestor, acceptor, collectionMint, redeemAuthority) {
        return _ref.apply(this, arguments);
    };
}();
export var findCollectionNumberingAddress = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(collectionMint, name) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        bytes.utf8.encode("numbering"),
                        collectionMint.toBuffer(),
                        new Uint8Array([
                            1
                        ]),
                        bytes.utf8.encode(name), 
                    ], COLLECTION_DISTRIBUTOR_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findCollectionNumberingAddress(collectionMint, name) {
        return _ref.apply(this, arguments);
    };
}();
export var findCollectionNumberingPda = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(collectionMint, name) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        bytes.utf8.encode("numbering"),
                        collectionMint.toBuffer(),
                        bytes.utf8.encode(name), 
                    ], COLLECTION_DISTRIBUTOR_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 2), address = _param[0], bump = _param[1];
                        return {
                            address: address,
                            bump: bump
                        };
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findCollectionNumberingPda(collectionMint, name) {
        return _ref.apply(this, arguments);
    };
}();
export var findDepositTrackerAddress = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(reserve, user) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return PublicKey.findProgramAddress([
                        Buffer.from("deposit-tracker"),
                        reserve.toBuffer(),
                        user.toBuffer()
                    ], COLLECTION_DISTRIBUTOR_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    });
                case 2:
                    return _ctx.abrupt("return", _ctx.sent);
                case 3:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findDepositTrackerAddress(reserve, user) {
        return _ref.apply(this, arguments);
    };
}();
export var findMetadataArtifactAddress = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(itemMint, follower) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        itemMint.toBuffer(),
                        follower.toBuffer()
                    ], FOLLOWER_COLLECTION_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findMetadataArtifactAddress(itemMint, follower) {
        return _ref.apply(this, arguments);
    };
}();
export var findCollectionReserveAddress = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(numbering, name) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        bytes.utf8.encode("reserve"),
                        numbering.toBuffer(),
                        new Uint8Array([
                            1
                        ]),
                        bytes.utf8.encode(name.toLowerCase()), 
                    ], COLLECTION_DISTRIBUTOR_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findCollectionReserveAddress(numbering, name) {
        return _ref.apply(this, arguments);
    };
}();
export var findTokenItemClaimAccountAddress = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(collectionReserve, itemId, tokenMint) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        bytes.utf8.encode("claim"),
                        collectionReserve.toBuffer(),
                        itemId.toBuffer(),
                        tokenMint.toBuffer(), 
                    ], COLLECTION_DISTRIBUTOR_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findTokenItemClaimAccountAddress(collectionReserve, itemId, tokenMint) {
        return _ref.apply(this, arguments);
    };
}();
export var findNativeItemClaimAccountAddress = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(collectionReserve, itemId) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        bytes.utf8.encode("claim"),
                        collectionReserve.toBuffer(),
                        itemId.toBuffer(),
                        bytes.utf8.encode("native"), 
                    ], COLLECTION_DISTRIBUTOR_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findNativeItemClaimAccountAddress(collectionReserve, itemId) {
        return _ref.apply(this, arguments);
    };
}();
export var findCollectionTokenReserveAddress = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(reserveAddress, mintAddress) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        bytes.utf8.encode("token_reserve"),
                        reserveAddress.toBuffer(),
                        mintAddress.toBuffer(), 
                    ], COLLECTION_DISTRIBUTOR_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findCollectionTokenReserveAddress(reserveAddress, mintAddress) {
        return _ref.apply(this, arguments);
    };
}();
export var findItemIdAddress = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(numbering, itemMint) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        bytes.utf8.encode("id"),
                        numbering.toBuffer(),
                        itemMint.toBuffer()
                    ], COLLECTION_DISTRIBUTOR_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findItemIdAddress(numbering, itemMint) {
        return _ref.apply(this, arguments);
    };
}();
export var findItemIdPda = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(numbering, itemMint) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        bytes.utf8.encode("id"),
                        numbering.toBuffer(),
                        itemMint.toBuffer()
                    ], COLLECTION_DISTRIBUTOR_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 2), address = _param[0], bump = _param[1];
                        return {
                            address: address,
                            bump: bump
                        };
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findItemIdPda(numbering, itemMint) {
        return _ref.apply(this, arguments);
    };
}();
export var findFollowerCollectionConfigAddress = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(collectionMint) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        anchor.utils.bytes.utf8.encode("config"),
                        collectionMint.toBuffer()
                    ], FOLLOWER_COLLECTION_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findFollowerCollectionConfigAddress(collectionMint) {
        return _ref.apply(this, arguments);
    };
}();
export var findFollowerCollectionConfig = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(collectionMint) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        anchor.utils.bytes.utf8.encode("config"),
                        collectionMint.toBuffer()
                    ], FOLLOWER_COLLECTION_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 2), address = _param[0], bump = _param[1];
                        return {
                            address: address,
                            bump: bump
                        };
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findFollowerCollectionConfig(collectionMint) {
        return _ref.apply(this, arguments);
    };
}();
export var findCollectionPatrolAddress = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(collectionMint) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        anchor.utils.bytes.utf8.encode("collection_patrol"),
                        collectionMint.toBuffer(), 
                    ], FOLLOWER_COLLECTION_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findCollectionPatrolAddress(collectionMint) {
        return _ref.apply(this, arguments);
    };
}();
export var findTokenReserveAddress = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(reserveAddress, mintAddress) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        Buffer.from("token_reserve"),
                        reserveAddress.toBuffer(),
                        mintAddress.toBuffer(), 
                    ], COLLECTION_DISTRIBUTOR_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findTokenReserveAddress(reserveAddress, mintAddress) {
        return _ref.apply(this, arguments);
    };
}();
export var findMemoAddress = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(reserve, depositor, nonce) {
        var nonceBytes;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    nonceBytes = new anchor.BN(nonce).toArrayLike(Buffer, "le", 4);
                    _ctx.next = 3;
                    return PublicKey.findProgramAddress([
                        Buffer.from("memo"),
                        reserve.toBuffer(),
                        depositor.toBuffer(),
                        nonceBytes
                    ], COLLECTION_DISTRIBUTOR_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    });
                case 3:
                    return _ctx.abrupt("return", _ctx.sent);
                case 4:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findMemoAddress(reserve, depositor, nonce) {
        return _ref.apply(this, arguments);
    };
}();
/*
  CATAPULT LOCATORS
*/ export var findCollectionNumbering = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(collectionMint) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        anchor.utils.bytes.utf8.encode("collection_numbering"),
                        collectionMint.toBuffer(), 
                    ], CATAPULT_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findCollectionNumbering(collectionMint) {
        return _ref.apply(this, arguments);
    };
}();
export var findItemNumber = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(numbering, itemMint) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        anchor.utils.bytes.utf8.encode("item_number"),
                        numbering.toBuffer(),
                        itemMint.toBuffer(), 
                    ], CATAPULT_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findItemNumber(numbering, itemMint) {
        return _ref.apply(this, arguments);
    };
}();
export var findCatapult = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(numbering) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        anchor.utils.bytes.utf8.encode("catapult"),
                        numbering.toBuffer()
                    ], CATAPULT_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findCatapult(numbering) {
        return _ref.apply(this, arguments);
    };
}();
export var findNextLaunch = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(catapult, catapultProgram) {
        var catapultInfo, nextLaunchIndex;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return catapultProgram.account.catapult.fetch(catapult);
                case 2:
                    catapultInfo = _ctx.sent;
                    nextLaunchIndex = catapultInfo.numLaunches;
                    return _ctx.abrupt("return", findLaunch(catapult, nextLaunchIndex));
                case 5:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findNextLaunch(catapult, catapultProgram) {
        return _ref.apply(this, arguments);
    };
}();
export var findLaunch = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(catapult, launchIndex) {
        var launchIndexBytes;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    launchIndexBytes = launchIndex.toArrayLike(Buffer, "le", 8);
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        anchor.utils.bytes.utf8.encode("launch"),
                        catapult.toBuffer(),
                        Buffer.from(launchIndexBytes), 
                    ], CATAPULT_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 2:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findLaunch(catapult, launchIndex) {
        return _ref.apply(this, arguments);
    };
}();
export var createLaunchAddress = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(catapult, launchIndex, bump) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.createProgramAddress([
                        anchor.utils.bytes.utf8.encode("launch"),
                        catapult.toBuffer(),
                        Buffer.from(launchIndex.toArrayLike(Buffer, "le", 8)),
                        new Uint8Array([
                            bump
                        ]), 
                    ], CATAPULT_PROGRAM_ID).then(function(address) {
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function createLaunchAddress(catapult, launchIndex, bump) {
        return _ref.apply(this, arguments);
    };
}();
export var findAtaCustodiedTokenPayload = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(tokenMint, launch) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", SplToken.getAssociatedTokenAddress(tokenMint, launch, true));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findAtaCustodiedTokenPayload(tokenMint, launch) {
        return _ref.apply(this, arguments);
    };
}();
export var findPayloadMemo = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(launch, payloadIndex) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        anchor.utils.bytes.utf8.encode("payload_memo"),
                        launch.toBuffer(),
                        new anchor.BN(payloadIndex).toArrayLike(Buffer, "le", 2), 
                    ], CATAPULT_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findPayloadMemo(launch, payloadIndex) {
        return _ref.apply(this, arguments);
    };
}();
export var findItemNumberAttribution = function() {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(numbering, number) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", PublicKey.findProgramAddress([
                        anchor.utils.bytes.utf8.encode("in_attribution"),
                        numbering.toBuffer(),
                        number.toArrayLike(Buffer, "le", 8), 
                    ], CATAPULT_PROGRAM_ID).then(function(param) {
                        var _param = _slicedToArray(param, 1), address = _param[0];
                        return address;
                    }));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function findItemNumberAttribution(numbering, number) {
        return _ref.apply(this, arguments);
    };
}();
