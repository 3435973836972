export var TypographyTheme = {
    variant: {
        "small-caption": "text-size-0.625",
        caption: "text-xs",
        body1: "text-sm",
        body2: "text-base",
        bodyTitle: "text-sm",
        title: "text-lg",
        h6: "text-xl",
        h5: "text-2xl",
        h4: "text-3xl",
        h3: "text-4xl",
        h2: "text-5xl",
        h1: "text-6xl"
    },
    textColor: {
        "small-caption": "primaryBlack-400",
        caption: "primaryBlack-400",
        body1: "primaryBlack-400",
        body2: "primaryBlack-400",
        bodyTitle: "primaryBlack-400",
        title: "primaryBlack-400",
        h6: "primaryBlack-400",
        h5: "primaryBlack-400",
        h4: "primaryBlack-400",
        h3: "primaryBlack-400",
        h2: "primaryBlack-400",
        h1: "primaryBlack-400"
    }
};
