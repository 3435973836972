export var IDL = {
    version: "0.1.0",
    name: "drop_tokens",
    instructions: [
        {
            name: "makeToken",
            accounts: [
                {
                    name: "creator",
                    isMut: true,
                    isSigner: true
                },
                {
                    name: "mint",
                    isMut: true,
                    isSigner: true
                },
                {
                    name: "patrol",
                    isMut: true,
                    isSigner: false
                },
                {
                    name: "rent",
                    isMut: false,
                    isSigner: false
                },
                {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false
                },
                {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false
                }, 
            ],
            args: []
        },
        {
            name: "airdrop",
            accounts: [
                {
                    name: "receiver",
                    isMut: true,
                    isSigner: true
                },
                {
                    name: "receiverTokenAccount",
                    isMut: true,
                    isSigner: false
                },
                {
                    name: "mint",
                    isMut: true,
                    isSigner: false
                },
                {
                    name: "patrol",
                    isMut: false,
                    isSigner: false
                },
                {
                    name: "rent",
                    isMut: false,
                    isSigner: false
                },
                {
                    name: "associatedTokenProgram",
                    isMut: false,
                    isSigner: false
                },
                {
                    name: "tokenProgram",
                    isMut: false,
                    isSigner: false
                },
                {
                    name: "systemProgram",
                    isMut: false,
                    isSigner: false
                }, 
            ],
            args: [
                {
                    name: "amount",
                    type: "u64"
                }, 
            ]
        }, 
    ],
    accounts: [
        {
            name: "patrol",
            type: {
                kind: "struct",
                fields: [
                    {
                        name: "bump",
                        type: "u8"
                    }, 
                ]
            }
        }, 
    ]
};
